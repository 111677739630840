// .common-button{
//     background-color: #ffffff;
//     outline: none;
//     box-shadow: none;
//     border-radius: 10px;
//     font-size: 18px;
//     border: none;
//     width: 145px;
//     height: 45px;
// }
// .main-header-top-change .button-header-nav{
//     background-color: #ffffff;
//     outline: none;
//     border: none;
//     width: 145px;
//     height: 45px;
//     border-radius: 10px;
//     font-family: 'Nunito';
//     font-style: normal;
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 19px;
//     color: #4F4E69;
//     margin-right: 20px;
//     margin-left: 20px;
//     transition: .4s ease-in-out;
//     &:hover{
//         box-shadow: 0 0.1rem 0.5rem rgb(194, 194, 199);
//     }
// }
// .main-header-top-change .button-header-filter{
//     background-color: #ffffff;
//     outline: none;
//     border: none;
//     width: 145px;
//     height: 45px;
//     border-radius: 10px;
//     font-family: 'Nunito';
//     font-style: normal;
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 19px;
//     color: #4F4E69;
//     transition: .4s ease-in-out;
//     &:hover{
//         box-shadow: 0 0.1rem 0.5rem rgb(194, 194, 199);
//     }
// }


.boxxxxx{
  width: 22px;
  height: 22px;
  background: #3553D5;
}
a.btn.warrrr {
  box-shadow: none;
}
.detailbtn-common {
  border-radius: 60px;
  background: var(
    --Buttons-Gradient,
    linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
  );
  padding: 12px 25.578px 11px 31px;
  text-decoration: none;
  border: none;
  width: 100%;
  color: var(--White, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:hover {
    box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3) !important;
  }

  // &:hover {
  //   color: #fff;
  //   box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3);
  //   background-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  // }
  // &:active {
  //   color: #fff !important;
  //   background-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  // }
  &:focus {
    outline: none;
  }
}
.btn-common {
  cursor: pointer !important;
  border-radius: 60px;
  background: var(
    --Buttons-Gradient,
    linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
  );
  padding: 17.433px 0px 17.567px 0px;
  text-decoration: none;
  border: none;
  width: 100%;
  color: var(--White, #fff);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;

  &:hover {
    color: #fff;
    box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3) !important;

    background-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  }
  &:active {
    color: #fff !important;
    background-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  }
  &:focus {
    outline: none;
  }
}
.buttons-remove {
  display: flex;
  /* padding: 14px; */
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.ptb {
  padding: 60px 0;
}
.red-b {
  outline: none;
  box-shadow: none;
  border-radius: 60px;
  background: var(
    --Buttons-Gradient,
    linear-gradient(90deg, #3553d5 50.15%, #3568c1 217.12%)
  );
  width: 170px;
  height: 50px;
  border: 1px solid #3568c1;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-right: 20px;
  transition: 0.4s ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #3568c1;
    color: #3568c1;

    box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3) !important;
  }
}
a:hover, a:focus {
 
  color: unset;
  
}
.red-w {
  outline: none;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #3568c1;
  box-sizing: border-box;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3568c1;
  transition: 0.4s ease-in-out;

  &:hover {
    background: linear-gradient(180deg, #3568c1 0%, #3568c1 100%);
    color: #ffffff;
    border: 1px solid #3568c1;

    box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3) !important;
  }
}
.btn-red {
  background: #c44e3e;
  border: 1px solid #be3817;
  box-sizing: border-box;
  border-radius: 6px;
  color: #fff;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 13px;
  width: 100%;
  &:hover {
    box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3) !important;
  }
}
.button-red {
  border-radius: 60px;
  background: var(
    --Red-Gradient,
    linear-gradient(180deg, #f04914 0%, #d0390a 100%)
  );
  display: flex;
  padding: 12px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  &:hover {
    box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3) !important;
  }
}

.dropdown.amer_dropdonfstsstt {
  button#dropdownMenuButton {
    display: flex;
    width: 147px;
    padding: 11px 15px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1.5px solid #e1ecff;
    background: var(--White, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-shadow: none !important;
    text-transform: capitalize;
  }
  .dropdown-menu.menubardrop.show {
    display: flex;
    width: 147px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1.5px solid #e1ecff;
    background: var(--White, #fff);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    a.dropdown-item.dropitmes {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.main-outerp {
  display: flex;
  align-items: center;
  gap: 13px;
  padding-top: 10px;
  padding-bottom: 10px;

  .verfiylabble {
    color: #030229;
    font-family: Poppins;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
  }
}


.btn-cancel {
  background: #ffffff;
  border: 1px solid #3568c1;
  border-radius: 150px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  // background: linear-gradient(89.93deg, #3553d5 50.15%, #3553d5 217.12%);
  color: #3568c1;
}
