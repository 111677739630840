.videos {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;

  table {
    tbody {
      tr {
        td {
          //   line-height: 80px;
          width: auto;
        }
      }
    }
  }
  

  .desci {
    overflow: hidden;
    width: 195px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  table tbody .trSimple {
    border-bottom: 1px solid transparent !important;

    td {
      // line-height: 30px !important;
      position: relative;
      // top: 24px;
    }

    td:nth-child(1) {
      position: relative;
      top: 0px;
      left: -20px;
    }
  }

  .onlyfortablepaddng {
    padding: 16px 14px !important;
  }

  table tbody .trLevel {
    border-top: 2px solid #ffffff !important;

    td {
      border-top: none !important;
    }
  }

  .videodropdown {
    border-radius: 8px;
    border: 1.5px solid #e1ecff;
    background: #fff;

    .internalss {
      display: flex;
      width: 171px !important;
      padding: 11px 15px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .justifycontentend {

    justify-content: space-between;
    align-items: center;
    padding: 14px !important;

  }

  .inputtextss {
    color: #000;
    font-family: Poppins;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .chec {
    .checkbox-button {
      cursor: pointer;
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      display: flex;
      align-items: self-start;

      .level {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .levelP {
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          color: #000;
          font-family: "Poppins", sans-serif;
          padding-top: 3px;
        }

        .numbersss {
          color: #000;
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          margin-top: 5px;
        }
      }
    }

    input {
      font-size: 1rem;
      line-height: 1.5;
      padding: 11px 23px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      outline: 0;
      background-color: transparent;
    }

    .checkbox-button__input {
      opacity: 0;
      position: absolute;
    }

    .checkbox-button__control {
      position: relative;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 12px;
      vertical-align: middle;
      // background-color: inherit;
      color: #3654d6;
      // border: 2px solid #666;
      // border-radius: 5px;

      // background: #20242A;

      border-radius: 2px;
      border: 1.5px solid #3654d6;
      background: #fff;
    }

    .checkbox-button__input:checked+.checkbox-button__control:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      border-radius: 1px;
      background: #3654d6;
    }

    .checkbox-button__input:checked+.checkbox-button__control {
      border-color: #3654d6;
    }

    .checkbox-button__control {
      transform: scale(0.75);
    }
  }
}

.levelTitle {
  vertical-align: sub !important;

  h6 {
    color: #000;
    font-family: Poppins;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
  }
}
.reddettllss{
  color: #3568C1;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
}
.button-main-daily_new{
  outline: none;
  box-shadow: none;
  border-radius: 10px;
  width: 170px;
  height: 50px;
  /* border: 1px solid #E72D1D !important; */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff !important;
  margin-right: 20px;
  transition: 0.4s ease-in-out;
  border-radius: 60px;
  /* background: #f04914 !important; */
  border-radius: 60px;
  background: #3568C1;
  border: 1px #3568C1;

}
.videos-detail {
  width: 100%;
  min-height: 100vh;

  .questions {
    margin-bottom: 15px;
    margin-top: 40px;

    .green {
      color: #4ce71d;
    }

    .quectionhnd {
      color: var(--Text-Black, #222);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Poppins;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 100%;
      /* 16px */
      letter-spacing: 0.1px;
      padding-bottom: 15px;
    }
  }

  .quectinpara {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 16px */
  }

  .onlyspanbold {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 16px */
    // padding-right: 10px;
    margin-right: 10px;
  }

  .qustintopdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .answercrtt {
    color: var(--Dark, #030229);
    font-family: Poppins;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .video-title {
    padding-top: 30px;

    h4 {
      color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
      padding-bottom: 13px;
    }

    .title {
      background: #fafafb;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 20px 22px;

      h6 {
        color: #030229;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 14px */
      }
    }
  }

  .description {
    h4 {
      color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
      padding-top: 30px;
    }

    .inner-dec {
      background: #fafafb;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 20px 20px 46px;

      p {
        // font-family: "Poppins", sans-serif;
        // font-style: normal;
        // font-weight: 600 !important;
        // font-size: 16px !important;
        // line-height: 22px !important;
        // color: #000000 !important;

        color: #030229;
        font-family: Poppins;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
      }

      .pad {
        padding-top: 30px;
      }
    }
  }

  .RUC {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    flex-wrap: wrap;
    padding-bottom: 40px;

    p {
      color: #626262;
      font-family: Poppins;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 14px */
      padding-bottom: 10px;
    }

    h6 {
      color: #030229;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      /* 16px */
    }

    .RUC-inner {
      padding-bottom: 20px;
    }
  }

  .buttons {
    display: flex;
    width: 351px;
    align-items: flex-start;
    gap: 20px;

    .red-b {
      outline: none;
      box-shadow: none;
      background: linear-gradient(180deg, #e72d1d 0%, #e71d36 100%);
      border-radius: 60px;
      width: 170px;
      height: 50px;
      border: 1px solid #e72d1d;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
      margin-right: 20px;
      transition: 0.4s ease-in-out;

      &:hover {
        background: transparent;
        border: 1px solid #3568c1;
        color: #3568c1;
      }
    }

    .red-g {
      outline: none;
      box-shadow: none;
      background: linear-gradient(180deg, #4ce71d 0%, #2ee71d 100%);
      border-radius: 60px;
      width: 170px;
      height: 50px;
      border: 1px solid #4ce71d;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
      margin-right: 20px;
      transition: 0.4s ease-in-out;

      &:hover {
        background: transparent;
        border: 1px solid #3568c1;
        color: #3568c1;
      }
    }

    .red-w {
      outline: none;
      box-shadow: none;
      background: #ffffff;
      border: 1px solid #3568c1;
      box-sizing: border-box;
      border-radius: 60px;
      width: 150px;
      height: 50px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #3568c1;
      transition: 0.4s ease-in-out;

      &:hover {
        background: transparent;
        border: 1px solid #3568c1;
        color: #3568c1;
      }
    }
  }

  .image {
    padding: 8px;
    // border: 1px solid #000;
    border-radius: 5px;
    border: 1px dashed #c6c6c6;
    background: var(--White, #fff);

    img {
      width: 100%;
    }

    // h4 {
    //   font-family: "Nunito" !important;
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 16px;
    //   line-height: 22px;
    //   color: #000000;
    //   padding-bottom: 13px;
    // }
  }

  .videolable {
    color: #000;
    font-family: Poppins;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 100%;
    /* 14px */
    padding-bottom: 15px;
    padding-top: 25px;
  }
}

td.main-image.newwwwflexx {
  /* display: flex; */
  /* align-items: center; */
  display: flex;
  /* width: 265.03px; */
  /* height: 64px; */
  /* padding: 12px 24px; */
  align-items: center;
  gap: 16px;
}

.forwirthonlllyyy {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px !important;
  display: block;
}

.videotablefont {
  color: #5f6d7e !important;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.014px;
}

.imagesec {
  padding: 8px;
  // border: 1px solid #000;
  border-radius: 5px;
  border: 1px dashed #c6c6c6;
  background: var(--White, #fff);
  max-width: 386px;
  height: 136px;
  width: 100%;

  img {
    width: 100%;
  }
}
.videotablepadingonly{
  padding: 5px 0px 0px 20px !important;

}
.forwidthonly{
  overflow: hidden;
  width: 142px !important;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.forwirthonlllyyynew{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 110px !important;
  display: block;

}
.internalssvvv{
  width: 130px !important;
  padding: 10px !important;
}
.addvideo {
  .inner-submit-lower-div {
    padding-top: 30px;

    .main-line {
      height: 0.4px;
      width: 100%;
      opacity: 0.5;
      border: 1px solid #dcdce4;
    }

    .form-group {
      .padd-top {
        padding-top: 30px;
      }

      label {
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 14px */
      }

      input {
        background: #fafafb;
        border: 1px solid #efefef;
        box-sizing: border-box;
        border-radius: 5px;
        height: 65px;
        width: 100%;
        box-shadow: none;
        padding: 17px 20px;
        color: #030229;
        text-transform: capitalize;

        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }

      textarea {
        background: #fafafb;
        border: 1px solid #efefef;
        box-sizing: border-box;
        border-radius: 5px;

        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }

      .main-add-ques {
        display: flex;
        justify-content: start;
        align-items: center;

        .main-question {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #133572;
          padding-left: 14px;
        }
      }

      .videopage {
        margin-top: 70px;

        .red-b {
          outline: none;
          box-shadow: none;
          background: var(--Buttons-Gradient,
              linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%));
          border-radius: 60px;
          width: 170px;
          height: 50px;
          border: 1px solid #3553d5;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin-right: 20px;
          transition: 0.4s ease-in-out;

          &:hover {
            background: transparent;
            border: 1px solid #3568c1;
            color: #3568c1;
          }
        }

        .red-w {
          outline: none;
          box-shadow: none;
          background: #ffffff;
          border: 1px solid #3568c1;
          box-sizing: border-box;
          border-radius: 60px;
          width: 150px;
          height: 50px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #3568c1;
          transition: 0.4s ease-in-out;

          &:hover {
            background: linear-gradient(180deg, #3568c1 0%, #3568c1 100%);
            color: #ffffff;
            border: 1px solid #3568c1;
          }
        }
      }
    }

    .form-check {
      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #8b8b8b !important;
        line-height: 33px;
      }

      input[type="radio"] {
        width: 20px;
        height: 20px;
        background: linear-gradient(180deg,
            #133572 0%,
            #3568c1 100%) !important;
        border: 1.5px solid #133572 !important;
        margin-left: 0px;
        z-index: 1111;
      }
    }

    .rightside-for-group {
      .dashed-border-new {
        border: 1px dashed #c6c6c6;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: transparent;
        height: 287px;
        max-width: 386px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #cdcdcd;
          padding-top: 20px;
        }

        .main-image-div {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 911px) {
  .videos-detail {
    .image {
      margin-bottom: 40px;
    }
  }

  .addvideo {
    .inner-submit-lower-div {
      padding-bottom: 30px;
    }
  }
}

@media (max-width: 600px) {
  .videos-detail {
    .buttons {
      .red-b {
        width: 100%;
        margin-bottom: 20px;
      }

      .red-w {
        width: 100%;
      }
    }
  }

  .addvideo {
    .inner-submit-lower-div {
      .form-group {
        input {
          &::placeholder {
            font-size: 14px;
          }
        }

        textarea {
          &::placeholder {
            font-size: 14px;
          }
        }

        .videopage {
          margin-top: 20px;

          .red-b {
            width: 100%;
          }

          .red-w {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.dflexfortableonlyss {
  display: flex;
  /* height: 64px; */
  // padding: 12px 24px !important;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100% !important;
}

.productimgsss {
  width: 40px !important;
  height: 51px !important;
  // background: linear-gradient(180deg, #FFB800 0%, #FF8A00 101.75%), linear-gradient(180deg, #3456D0 0%, #2542A6 100%);
  border-radius: 5px;
  line-height: 120% !important;

  img.innerimgsss {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.sddsdsdsdsd {
  color: #5f6d7e !important;

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.014px !important;
}

button.dropbtn {
  border-radius: 140px;
  background: #f5f9ff;
}

@media (max-width: 400px) {
  .videos-detail {
    .RUC {
      .RUC-inner {
        padding-right: 30px;
      }
    }
  }
}

@media (max-width: 360px) {
  .videos-detail {
    .RUC {
      .RUC-inner {
        padding-right: 0px;
      }
    }
  }
}

.buttonsss_attri {
  background: transparent;
  border: none;
  padding: 0px;
  margin-top: 30px;

  img {
    padding-right: 12px;
  }
}

.button-removess {
  background: transparent;
  color: rgb(153, 11, 11);
  border: 1px solid rgb(153, 11, 11);
  border-radius: 12px;
  padding: 4px 8px;
}

.button-header-navss {
  width: 100%;
  background: #fafafb;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 5px;
  height: 65px;
  color: rgb(182, 176, 176);
  font-weight: 600;
}

.drop-down-menue-main {
  width: 100%;
  text-align: center;
  background: #fafafb;
  border: 1px solid #efefef;
}

#form-group1 {
  height: 40px !important;
  width: 24px !important;
  border: none;
  box-shadow: none !important;
}

.MuiSelect-select {
  background: #fafafb !important;
  border: 1px solid #efefef !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  height: 65px !important;
  width: 100% !important;
}

.MuiOutlinedInput-input {
  background: #fafafb !important;
  border: 1px solid #efefef !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  height: 65px !important;
  color: #495057 !important;
  width: 100% !important;
}

.MuiFormControl-root {
  width: 100%;
  padding: 20px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #efefef !important;
}

// .video-player__video {
//     margin-top: 1rem;
//     width: 145px!important;
//     height: 145px !important ;
//     max-width: unset!important;
//     margin: 0 auto;
// }

.video-player__video {
  margin-top: 1rem;
  max-width: 128px !important;
  margin: 0 auto;
  border-radius: 10px;
}

.react-player__preview {
  height: 216px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.videoscard {
  display: flex;
  width: 1135px;
  height: 919px;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #f3f7fb;
  background: var(--White, #fff);
}

.bbbfffddfdfdf {
  border-radius: 5px;
  border: 1px solid #f3f7fb;
  // background: #3498ff;
  display: flex;
  padding: 17px 20px;
  align-items: center;
  gap: 10px;
}

.drtuop {
  color: #cdcdcd;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  text-align: center;

  .commonfffr {
    background: var(--Buttons-Gradient,
        linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
}

// .nsjddddd {
//   position: absolute;
//   top: 129px;
//   left: 0;
//   right: 0;
//   width: 39%;
//   bottom: 0;
//   margin: 0 auto;
// }

.choose-filessssssdds {
  gap: 10px;
  border-radius: 5px;
  border: 1px dashed #c6c6c6;
  background: var(--White, #fff);
  width: 276px;
  height: 276px;
  margin-bottom: 20px;

  label {
    height: 100%;
    width: 100%;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

label.text-centerr {
  text-align: center;
}


.published-btn {
  // background: linear-gradient(180deg, #4ce71d 0%, #2ee71d 100%);
  // border-radius: 40px;
  // display: flex;
  // padding: 9px 37px;
  // justify-content: center;
  // align-items: center;
  // color: #3553d5;
  // font-size: 12px;
  // font-style: normal;
  // font-weight: 500;
  // line-height: 18px;
  // letter-spacing: -0.012px;
  // border: none;
  // outline: none;
  color: #4ce71d;
  padding: 10px 10px;
  border-radius: 5px;
}

.unpublished-btn {
  color: #e72d1d;
  padding: 10px 10px;
  border-radius: 5px;
  // background: linear-gradient(180deg, #e72d1d 0%, #e71d36 100%);
  // // background: #f5f9ff;
  // border-radius: 40px;
  // display: flex;
  // padding: 9px 37px;
  // justify-content: center;
  // align-items: center;
  // color: #3553d5;
  // font-size: 12px;
  // font-style: normal;
  // font-weight: 500;
  // line-height: 18px;
  // letter-spacing: -0.012px;
  // border: none;
  // outline: none;
}