.addbanner1234 {
  width: 100%;
  min-height: 100%;

  .inner-submit-lower-div {
    padding-top: 30px;
    padding-bottom: 30px;
    .main-line {
      height: 0.4px;
      width: 96%;
      opacity: 0.5;
      border: 1px solid #dcdce4;
    }
    .choose-category {
      padding-top: 30px;
    }
    .form-group {
      .padd-top {
        padding-top: 30px;
      }
      label {
        font-family: "Nunito" !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 16px !important;
        line-height: 22px !important;
        color: #000000 !important;
      }
      input {
        border-radius: 5px;
        border: 1px solid #EFEFEF;
        background: #FAFAFB;

        border-radius: 5px;
        height: 65px;
        box-shadow: none;
        cursor: pointer;
        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
      textarea {
        border: 1px solid #f3f7fb;
        background: var(--White, #fff);
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: none;

        color: #615f5f;

        border-radius: 5px;
        box-shadow: none;

        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: normal;
        }
      }
      .main-add-ques {
        display: flex;
        justify-content: start;
        align-items: center;
        .main-question {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #133572;
          padding-left: 14px;
        }
      }
      .videopage {
        margin-top: 70px;
        .red-b {
          outline: none;
          box-shadow: none;
          border-radius: 60px;
          background: var(
            --Buttons-Gradient,
            linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
          );
          width: 170px;
          height: 50px;
          border: 1px solid #3553d5;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin-right: 20px;
          transition: 0.4s ease-in-out;
          &:hover {
            background: transparent;
            border: 1px solid #3553d5;
            color: #3553d5;
          }
        }
        .red-w {
          outline: none;
          box-shadow: none;
          border-radius: 60px;
          border: 1px solid #3568c1;
          background: var(--White, #fff);
          box-sizing: border-box;

          width: 170px;
          height: 50px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #3568c1;
          transition: 0.4s ease-in-out;
          &:hover {
            background: var(
              --Buttons-Gradient,
              linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
            );
            color: #ffffff;
            border: 1px solid #3568c1;
          }
        }
      }
    }
    .form-check {
      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #030229 !important;
        line-height: 33px;
        box-shadow: none;
      }
      input[type="radio"] {
        width: 20px;
        height: 20px;
        background: linear-gradient(
          180deg,
          #133572 0%,
          #3568c1 100%
        ) !important;
        border: 1.5px solid #133572 !important;
        margin-left: 0px;
        z-index: 1111;
      }
    }

    .rightside-for-group {
      .dashed-border-new {
        border: 1px dashed #c6c6c6;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: transparent;
        height: 287px;
        width: 386px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px;
        p {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #cdcdcd;
          padding-top: 20px;
        }
        .main-image-div {
          text-align: center;
        }
      }
    }
  }
}


.newwwwwwwwwwwwwbtnn{
  box-shadow: none !important;
  display: flex;
  padding: 19px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;

}
.bbadges_flex {
  display: flex;
  width: 832px;
  align-items: flex-start;
  gap: 30px;
}
.notifications {
  width: 100%;
  min-height: 100vh;
}

.send-notifications {
  width: 100%;
  min-height: 100vh;
  .inner-submit-lower-div {
    .main-send-message {
      h3 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: #030229;
      }
    }
  }
}

img {
  object-fit: cover;
}
@media (max-width: 360px) {
  .send-notifications {
    .inner-submit-lower-div {
      .main-send-message {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}

.dashed-border-new {
  border: 1px dashed #c6c6c6;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: transparent;
  height: 287px;
  max-width: 386px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

iframe {
  position: fixed;
  top: unset;
  left: unset;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 2147483647;
}

@media (max-width: 600px) {
  .bbadges_flex {
    width: 100%;

    flex-direction: column;
  }
}


.mainimgset {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  object-position: center;
  .innerimgset {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
