img{
    max-width: 100%;
    border-radius: $border-radius-small;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}
// .imgages-no{
//     max-width: unset!important;
// }
.imgagesnos{

    
}