.addbanner1234 {
  width: 100%;
  min-height: 100%;

  .inner-submit-lower-div {
    padding-top: 30px;
    padding-bottom: 30px;
    .main-line {
      height: 0.4px;
      width: 96%;
      opacity: 0.5;
      border: 1px solid #dcdce4;
    }
    .choose-category {
      padding-top: 30px;
    }
    .form-group {
      .padd-top {
        // padding-top: 30px;
      }
      .nsjfhfjaslkfjs {
        display: flex;
        height: 86px;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        text-align: center;
      }
      .drtuop {
        color: #cdcdcd;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        .commonfffr {
          background: var(
            --Buttons-Gradient,
            linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
        }
      }
      label {
        color: #000 !important;

        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 100% !important;
      }
      input {
        border-radius: 5px;
        border: 1px solid #f3f7fb;
        background: var(--White, #fff);

        box-sizing: border-box;

        height: 65px;
        box-shadow: none;
        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
      textarea {
        background: #fafafb;
        border: 1px solid #efefef;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: none;
        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
      .main-add-ques {
        display: flex;
        justify-content: start;
        align-items: center;
        .main-question {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #133572;
          padding-left: 14px;
        }
      }
      .videopage {
        margin-top: 70px;
        .red-b {
          outline: none;
          box-shadow: none;
          border-radius: 60px;
          background: var(
            --Buttons-Gradient,
            linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
          );
          width: 170px;
          height: 50px;
          border: 1px solid #3553d5;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin-right: 20px;
          transition: 0.4s ease-in-out;
          &:hover {
            background: transparent;
            border: 1px solid #3568c1;
            color: #3568c1;
          }
        }
        .red-w {
          outline: none;
          box-shadow: none;
          background: #ffffff;
          border: 1px solid #3568c1;
          box-sizing: border-box;
          border-radius: 10px;
          width: 170px;
          height: 50px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #3568c1;
          transition: 0.4s ease-in-out;
          &:hover {
            background: linear-gradient(180deg, #133572 0%, #3568c1 100%);
            color: #ffffff;
            border: 1px solid #133572;
          }
        }
      }
    }
    .form-check {
      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #030229 !important;
        line-height: 33px;
      }
      input[type="radio"] {
        width: 20px;
        height: 20px;
        background: linear-gradient(
          180deg,
          #133572 0%,
          #3568c1 100%
        ) !important;
        border: 1.5px solid #133572 !important;
        margin-left: 0px;
        z-index: 1111;
      }
    }

    .rightside-for-group {
      .dashed-border-new {
        border: 1px dashed #c6c6c6;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: transparent;
        height: 287px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #cdcdcd;
          padding-top: 20px;
        }
        .main-image-div {
          text-align: center;
        }
      }
    }
  }
}
.notifications {
  width: 100%;
  min-height: 100vh;
}

.verfidprofilehnd{
  color: #030229;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 14px */
}
.mainouterdivcheckbon {
  display: flex;
  align-items: flex-start;
  gap: 67px;
  padding-top: 15px;
}
.onlyboderandcolor{
  border-radius: 5px;
  border: 1px solid #F3F7FB !important;
  background: var(--White, #FFF) !important;
}
.innertable-notificationss {
  .namemain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    .namepara {
      color: #5f6d7e;
      font-size: 13px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.013px;
    }
  }
  table {
    width: 100%;
    border-radius: 10px;
    // border: 1px solid #f3f7fb;
    background: var(--White, #fff);

    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    thead {
      padding: 0px;
      th {
        color: #5f6d7e;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.013px;
        cursor: pointer;
        padding: 20px;
        vertical-align: middle;
        border-bottom: 1px solid #f3f7fb;
        white-space: nowrap;
      }
    }
    tbody {
      padding: 0px;
      tr {
        td {
          color: #5f6d7e !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.014px;
          border-bottom: 1px solid #f3f7fb;
          background: var(--White, #fff);
          cursor: pointer;
          padding: 20px;
          vertical-align: middle;

          .detailbtn {
            border-radius: 40px;
            background: #f5f9ff;
            display: flex;
            padding: 9px 37px;
            justify-content: center;
            align-items: center;
            color: #3553d5;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.012px;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
  .onlyboldtext {
    color: #030229 !important;
    font-feature-settings: "clig" off, "liga" off;

    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
  }
}

.send-notifications {
  width: 100%;
  min-height: 100vh;
  .inner-submit-lower-div {
    .main-send-message {
      h3 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: #030229;
      }
    }
  }
}

img {
  object-fit: cover;
}
@media (max-width: 360px) {
  .send-notifications {
    .inner-submit-lower-div {
      .main-send-message {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}

.dashed-border-new {
  border: 1px dashed #c6c6c6;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: transparent;
  height: 287px;
  display: flex;
  justify-content: center;
  align-items: center;
}
