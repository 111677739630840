.tasks {
  .whitespace {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// this week rewards
.thisweekrewards {
  .whitespace {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// leaderboard
.leaderboard {
  .completed {
    color: #2cc84a;
  }
}

// TASK NEW SCSS

.main-tasks {
  .inerimgddd_task_table {
    display: flex;
    width: 91px;
    height: 58px;
    padding: 3px 20px 3px 19px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    background: #80a7ee;
    object-fit: cover;
    object-position: center;

    .taskimg {
      width: 85%;
      height: 85%;
      border-radius: 5px;
      object-fit: cover;
      object-position: center;
    }
  }

  .taskinertablesecrow {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    .boldstory {
      color: #030229;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    .Synergy {
      color: #030229;

      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .tasktablelast {
    display: flex;
    gap: 21px;
    align-items: center;
  }
}

.maintablea_task {
  padding: 0px 20px;
  border-radius: 10px;
  border: none;
  background: var(--White, #fff);

  .namemain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    .namepara {
      color: #5f6d7e;
      font-size: 13px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.013px;
    }
  }

  table {
    width: 100%;
    // border-radius: 10px;
    // border-bottom: 1px solid #f3f7fb;
    // background: var(--White, #fff);

    /* Shadow/XSM */
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    thead {
      padding: 0px;

      th {
        color: var(--Text, #030229);

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        /* 107.143% */
        letter-spacing: 0.1px;
        cursor: pointer;
        padding: 20px;
        vertical-align: middle;
        border-bottom: 1px solid #f3f7fb;
        white-space: nowrap;
        opacity: 0.7;
      }
    }

    tbody {
      padding: 0px;

      tr {
        td {
          color: #030229;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 21px */
          border-bottom: 1px solid #f3f7fb;
          background: var(--White, #fff);
          cursor: pointer;
          padding: 20px;
          vertical-align: middle;
          text-transform: capitalize;
          white-space: nowrap;

          .inerimgddd {
            width: 40px;
            height: 40px;
            border-radius: 30px;
            object-fit: cover;
            object-position: center;

            .tableimgginerdd {
              width: 100%;
              height: 100%;
              border-radius: 30px;
              object-fit: cover;
              object-position: center;
            }

            .tableimgginerddimgrafal {
              width: 100%;
              height: 100%;
              border-radius: 2px;
              object-fit: cover;
              object-position: center;
            }
          }

          .detailbtn {
            border-radius: 40px;
            background: #f5f9ff;
            display: flex;
            padding: 9px 37px;
            justify-content: center;
            align-items: center;
            color: #3553d5;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.012px;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}
.flexlkjalsdjlksend.modal-header {
  justify-content: flex-end !important;
}
// Add task
.addtask {
  width: 100%;
  min-height: 100%;

  .inner-submit-lower-div {
    padding-top: 30px;
    padding-bottom: 30px;

    .main-line {
      height: 0.4px;
      width: 96%;
      opacity: 0.5;
      border: 1px solid #dcdce4;
    }

    .choose-category {
      padding-top: 30px;
    }

    .form-group {
      .padd-top {
        padding-top: 30px;
      }

      label {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
      }

      input {
        border-radius: 5px;
        border: 1px solid #efefef;
        background: #fafafb;
        height: 65px;
        box-shadow: none;
        outline: none;
        color: #000000;

        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }

      textarea {
        border-radius: 5px;
        border: 1px solid #efefef;
        background: #fafafb;
        color: #000000;
        box-shadow: none;
        outline: none;
        padding: 20px;

        &::placeholder {
          color: #cdcdcd;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .main-add-ques {
        display: flex;
        justify-content: start;
        align-items: center;

        .main-question {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #133572;
          padding-left: 14px;
        }
      }

      .videopage {
        margin-top: 25px;

        .red-b {
          outline: none;
          box-shadow: none;
          border-radius: 60px;
          background: var(
            --Buttons-Gradient,
            linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
          );

          width: 170px;
          height: 50px;
          border: 1px solid #3553d5;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin-right: 20px;
          transition: 0.4s ease-in-out;

          &:hover {
            background: transparent;
            border: 1px solid #3553d5;
            color: #3553d5;
          }
        }

        .red-w {
          outline: none;
          box-shadow: none;
          border-radius: 60px;
          border: 1px solid #3568c1;
          background: var(--White, #fff);
          width: 150px;
          height: 50px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #3568c1;
          transition: 0.4s ease-in-out;

          &:hover {
            background: var(
              --Buttons-Gradient,
              linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
            );
            color: #ffffff;
            border: 1px solid #3568c1;
          }
        }
      }
    }

    .form-check {
      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #030229 !important;
        line-height: 33px;
      }

      input[type="radio"] {
        width: 20px;
        height: 20px;
        background: linear-gradient(
          180deg,
          #133572 0%,
          #3568c1 100%
        ) !important;
        border: 1.5px solid #133572 !important;
        margin-left: 0px;
        z-index: 1111;
      }
    }

    .rightside-for-group {
      .dashed-border-new {
        border: 1px dashed #c6c6c6;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: transparent;
        height: 287px;
        max-width: 386px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #cdcdcd;

          //   padding-top: 20px;
          span {
            background: var(
              --Buttons-Gradient,
              linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
            );

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
          }
        }

        .main-image-div {
          text-align: center;
        }
      }
    }
  }
}

// switch
.radioouterdiv {
  display: flex;
  justify-content: space-between;
  width: 50%;
  display: flex;
  align-items: center;
  /* gap: 60px; */
  margin-bottom: 100px;
}

.main-switch {
  display: flex;
  justify-content: start;
  align-items: center;

  h5 {
    color: #030229;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    line-height: 30px;
    padding-left: 70px;
    color: #030229;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    height: 25px;
    width: 53px;
    margin-top: 15px;
    margin-left: 14px;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #3654d6;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

//  reward details

.leaderdetails {
  .upper-detail-page {
    position: relative;
    padding: 45px 0px;
    border-radius: 10px;
    background: var(--White, #fff);

    .button-red {
      outline: none;
      border: none;
      box-shadow: none;
      padding: 13px 30px;
      background: linear-gradient(180deg, #e72d1d 0%, #e71d36 100%);
      border-radius: 10px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      transition: 0.3s ease-in-out;
      font-size: 14px;
      color: #ffffff;
      line-height: 19px;

      &:hover {
        box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3);
      }
    }

    .feildss {
      margin-bottom: 30px;

      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px !important;
        line-height: 19px;
        color: #818094;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px !important;
        line-height: 27px;
        color: #030229;
      }

      span {
        padding-left: 20px;
        cursor: pointer;
      }
    }

    .imsge-d {
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 11px 7px;

      img {
        width: 100%;
      }

      .modal-main {
        border-radius: 1.3rem;
      }
    }
  }

  .lower-upper-detail {
    table {
      .main-t-body-text {
        tr {
          td {
            line-height: 27px;
          }
        }

        .text-style-c {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          color: #030229;

          .main-size {
          }

          span {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: #5b5b5b;
          }

          .toggler-main {
            .custom-switch {
              .custom-control-label {
                &::after {
                  width: calc(1rem - 1px) !important;
                  height: calc(1rem - 1px) !important;
                }
              }

              &::before {
                width: 34px;
                height: 18.7px;
              }
            }
          }
        }

        .complete {
          color: #2cc84a;
          text-transform: capitalize !important;
        }

        .uncomplete {
          color: #c8432c;
          text-transform: capitalize !important;
        }
      }

      thead {
        th {
          font-size: 14px;
        }
      }
    }

    overflow-y: auto;

    h4 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #222222;
      padding-left: 0.75rem;
      padding-top: 0.75rem;
    }
  }

  .detail-card {
    text-align: center;
    cursor: pointer;

    p {
      margin-bottom: 0px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #030229;
      padding-top: 20px;
    }

    img {
      max-width: 100%;
      border-radius: 50%;
      width: 164px;
      // height: 164px;
      flex-shrink: 0;
      cursor: pointer;

      // border: 4px solid var(--Buttons-Gradient, #3553d5);
    }
  }

  .main-div-drop {
    width: 100%;

    .main-drop-down {
      .dropdown-menu {
        left: -29px !important;
        top: 5px !important;
        text-align: center !important;
      }

      button {
        img {
          padding-left: 20px;
        }
      }

      float: right;
      margin-top: 30px;
      margin-bottom: 50px;
      margin-right: 18px;

      .dropdown-toggless {
        // width: 130px;
        // height: 40px;
        padding: 17px;
        outline: none;
        box-shadow: none;

        border: none;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: var(--Text, #030229);
        // box-shadow: 0 0.1rem 0.5rem rgb(194, 194, 199);
        border-radius: 100px;
        background: var(--White, #fff);
        opacity: 0.7;
      }
    }
  }

  .main-modal-one {
    .custom-control {
      margin-top: 30px;
      padding-left: 0px !important;
    }

    .custom-switch {
      .pd-right {
        padding-right: 47px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px;
        color: #030229;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #2cc84a;
      background-color: #2cc84a;
    }
  }
}

.custom-control {
  margin-top: 30px;
  padding-left: 0px !important;
}

.custom-switch {
  .pd-right {
    padding-right: 47px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    color: #030229;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #2cc84a;
  background-color: #2cc84a;
}

.custom-control-label::before {
  background-color: #c9c9c9;
  width: 34px;
  height: 18.7px;
}

.custom-control-label::after {
  background-color: #ffffff !important;
  width: calc(1rem - 1px);
  height: calc(1rem - 1px);
}

.main-outter-caresoual {
  margin-top: 44px;
  margin-bottom: 30px;
}

.carousel-control-prev {
  fill: var(--White, #fff);
  stroke-width: 2px;
  stroke: var(--Buttons-Gradient, #3553d5);
  background-color: transparent;
  border-radius: 50px;
  // border: 2px solid #133572 !important;
  height: 34px;
  width: 34px;
  opacity: 1;
  top: 44%;
  left: 10px;
}

.carousel-control-next {
  background-color: transparent;
  border-radius: 50px;
  // border: 2px solid #133572 !important;
  height: 34px;
  width: 34px;
  opacity: 1;
  top: 44%;
  right: 10px;
}

.carousel-inner {
  width: 70%;
  margin: 0 auto;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.8s ease-in-out;
  text-align: center;
}

.carousel-control-prev {
  background-color: transparent;
  border-radius: 50px;
  // border: 2px solid #133572 !important;
  height: 34px;
  width: 34px;
  opacity: 1;
  top: 44%;
  left: 10px;
}
.close {
  opacity: 3.5;
}
.carousel-control-next {
  background-color: transparent;
  border-radius: 50px;
  // border: 2px solid #133572 !important;
  height: 34px;
  width: 34px;
  opacity: 1;
  top: 44%;
  right: 10px;
}

.toggler-main .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #2cc84a;
  background-color: #2cc84a;
}

.toggler-main .custom-switch .custom-control-label::before {
  background-color: #c9c9c9;
  width: 34px;
  height: 18.7px;
}

.toggler-main .custom-switch .custom-control-label::after {
  background-color: #ffffff;
  width: calc(1rem - 1px);
  height: calc(1rem - 1px);
}

// leadership
.innertable_leaderBoard {
  .namemain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    .namepara {
      color: #5f6d7e;
      font-size: 13px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.013px;
    }
  }

  table {
    width: 100%;

    thead {
      padding: 0px;

      th {
        color: #5f6d7e;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.013px;
        cursor: pointer;
        padding: 20px;
        vertical-align: middle;
        // border-bottom: 1px solid #f3f7fb;
        white-space: nowrap;
      }
    }

    tbody {
      padding: 0px;

      tr {
        td {
          .eleipiess {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            display: block;
            white-space: nowrap !important;
            width: 100%;
            cursor: pointer;
          }

          .mainimgdiv {
            display: flex;

            align-items: center;
            gap: 16px;

            .inerimgd {
              width: 32px;
              height: 32px;
              border-radius: 30px;
              object-fit: cover;
              object-position: center;

              .tableimgginer {
                width: 100%;
                height: 100%;
                border-radius: 30px;
                object-fit: cover;
                object-position: center;
              }
            }

            .tableimgtext {
              color: #2e3646;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.014px;
              white-space: nowrap;
            }
          }

          color: #5f6d7e;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.014px;
          border-top: 1px solid #f3f7fb;
          background: var(--White, #fff);
          cursor: pointer;
          padding: 20px;
          vertical-align: middle;

          .detailbtn {
            border-radius: 40px;
            background: #f5f9ff;
            display: flex;
            padding: 9px 37px;
            justify-content: center;
            align-items: center;
            color: #3553d5;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.012px;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}

.tableheadnew {
  color: var(--Text, #030229) !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.1px;
  opacity: 1 !important;
}

.modal-collection-show .modal-content {
  border-radius: 28px !important;
}

.rrrsrsr {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rrrsrsrr {
  max-width: 60px;
}

.newbarrrr {
  display: flex;
  align-items: center;
  gap: 15px;
}

.newtdclassss {
  color: #2cc84a !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.nameddiv {
  text-align: center;
}

.mainymore {
  text-align: center;
}

.heydiv {
  text-align: center;
  margin: 0 auto;
}

.carousel-control-prev-icon {
  background-image: url(../../assets/img/next.png);
}

.carousel-control-next-icon {
  background-image: url(../../assets/img/fssst.png);
}

.carousel-control-next {
  right: 8px;
  // position: fixed;
  // top: -560px;
}

.carousel-control-prev {
  left: 8px;
  // top: -560px;
  // position: fixed;
}

.dafdadfasasfasf {
  margin: 0px auto;
  padding: 20px;
}

// .addrewadsssdd{
//   max-width: 547px !important;
//   margin: 0px auto !important;
//   padding: 20px !important;
//   width: 100% !important;

// }

.addrewadsssdd .modal-dialog .modal-content .modal-header .modal-title {
  text-transform: capitalize !important;
  font-size: 25px !important;
}

.sidedotsaa {
  background: #ffffff;
  border: 1px dashed #c6c6c6;
  border-radius: 5px;

  width: 100%;
  height: 287px;
  display: flex;
  align-items: center;
  flex-direction: column;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 381px;
}

.ambmodalmain {
  .modal-dialog {
    max-width: 672px;

    .modal-content {
      border-radius: 20px;
      border: 1px solid #f3f7fb;
      background: var(--White, #fff);
      backdrop-filter: blur(2px);
      // padding: 20px;
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px 0px;
      border-bottom: none !important;

      .modal-title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        text-transform: uppercase;
        margin: 0px;
      }

      .close {
        background: url("../../../src/ammod.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 32px;
        height: 32px;
        opacity: unset !important;
        border: none;
        margin: 0;
        padding: 0;
        box-shadow: none !important;

        span {
          display: none !important;
        }
      }
    }

    .modal-body {
      padding: 20px 20px;

      .mod_bodydiv {
        border-radius: 10px;
        background: #f7f8fd;
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;

        .topdiv {
          display: flex;
          align-items: center;
          gap: 20px;

          .top_rght {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            h5 {
              color: #2e3646;
              text-align: center;

              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.018px;
            }

            .modprobtn {
              display: flex;
              padding: 8px 21px;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              border-radius: 40px;
              background: #0f7efe;
              color: var(--White, #fff);
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.012px;
              border: none;
            }
          }
        }

        .modfsrflex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .fsteft {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
          }

          .fsteftsec {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
          }

          h6.usernnamee {
            color: #818094;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }

          h6.namefullletf {
            color: var(--Text, #030229);
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;

            .walletaddresss {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              color: var(--Text, #030229);
              white-space: nowrap;
              width: 97px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .mod_bodydivsec {
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
        border-radius: 10px;
        background: #f7f8fd;
        margin-top: 20px;
        margin-bottom: 20px;

        .modfsrflex {
          display: flex;
          align-items: flex-start;
          gap: 20px;
          align-self: stretch;

          .fsteft {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            gap: 10px;

            .usernnamee {
              color: #818094;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
            }

            .namefullletf {
              color: #030229;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              letter-spacing: 0.14px;
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }

          .fsteftsec {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            gap: 10px;

            h6.usernnamee {
              color: #818094;

              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
            }

            .namefullletf {
              color: #030229;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              letter-spacing: 0.14px;
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }

    .lastfoterbtn {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .rreject {
        border-radius: 60px;
        background: var(
          --Red-Gradient,
          linear-gradient(180deg, #f04914 0%, #d0390a 100%)
        );
        padding: 13px 59px;
        width: 100%;
        color: var(--White, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
      }

      .approveeedd {
        border-radius: 60px;
        background: linear-gradient(180deg, #15fa2c 0%, #0dd721 100%);
        color: var(--White, #fff);
        text-align: center;
        border: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 100%;
        padding: 13px 59px;
      }
    }
  }
}

@media (max-width: 600px) {
  .leaderdetails {
    .main-modal-one {
      .modal-body-main {
        .main-outter-caresoual {
          .carousel-control-prev {
            left: 6px;
          }

          .carousel-control-next {
            right: 6px;
          }
        }

        padding: 0px !important;
      }
    }
  }

  .addtask {
    .inner-submit-lower-div {
      .form-group {
        textarea {
          &::placeholder {
            font-size: 14px;
          }
        }

        input {
          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }

    .videopage {
      margin-top: 20px !important;

      .red-b {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .red-w {
        width: 100% !important;
      }
    }
  }
}

.nnneerfdsfdsf {
  border-radius: 50%;
  object-position: center;
  object-fit: fill;
  width: 164px;
  height: 140px;
  flex-shrink: 0;
  overflow: hidden;
}

// @media(max-width: 991px){
//     .tasks{
//         .table-responsive.overflow-responce{
//             overflow-x: auto!important;
//         }
//     }
// }

.imgages-no {
  width: 65px !important;
  border-radius: 50%;
}

.fa-check:before {
  content: "\f00c";
  color: green;
  font-size: 32px;
}

.imgages-nonew {
  width: 40px !important;

  border-radius: 4px;
}

.main-image {
  width: 40px;
  height: 40px;
  border-radius: 4px;

  img {
    width: 40px;
    // height: 40px;
    border-radius: 4px;
  }
}

.modal-collection-show .modal-content {
  border-radius: 28px !important;
}

.rrrsrsr {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rrrsrsrr {
  max-width: 60px;
}

.custom-control-label {
  cursor: pointer !important;
}

.table {
  margin-top: 0px !important;
}

.forbolddddd {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.forstyle {
  width: 91px;
  height: 58px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;

  .imgage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.dragimg {
  margin: 0 auto;
  display: flex;
}

.mypointttt {
  color: #cdcdcd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.paraa2 {
  background: var(
    --Buttons-Gradient,
    linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.manydivbart {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// SWITCH

.switch {
  position: relative;
  display: inline-block;
  width: 44.003px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 3px;
  bottom: 1.8px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background: #2cc84a;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(18px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
