
  .main-soop{
    font-size: 16px !important;
    white-space: pre-line;
    line-height: 24px;
    margin-top: 18px;
    width: 200px;
  }
.main-aop{
    width: 260px;
}

.faq table tbody tr td{
    line-height: 60px;
}

.notboldd{
  color: #030229 !important;

  font-size: 14px !important;

  font-weight: 400 !important;


}