.navbar {
  .bg-dark {
    background-color: #fff !important;
  }
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  min-height: 53px;
  margin-bottom: 20px;

  a {
    vertical-align: middle;

    &:not(.btn):not(.dropdown-item) {
      color: $white-color;
    }

    &.dropdown-item {
      color: $default-color;
    }
  }

  &.bg-white {
    .input-group .form-control,
    .input-group.no-border .form-control {
      color: $default-color;

      // @include placeholder(){
      //   color: $default-color;
      // };
    }
    .input-group-prepend .input-group-text i,
    .input-group-append .input-group-text i {
      color: $default-color;
      opacity: 0.5;
    }
  }

  .form-group,
  .input-group {
    margin: 0;
    margin-left: -3px;
    //   margin-right: 5px;

    .form-group-addon,
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      color: $default-color;

      i {
        opacity: 1;
      }
    }

    &.no-border {
      &:hover {
        .form-control {
          box-shadow: 0 0.1rem 0.5rem rgb(194, 194, 199);
        }
        .input-group-append {
          .input-group-text {
            box-shadow: 0 0.1rem 0.5rem rgb(194, 194, 199);
          }
        }
      }
      .input-group-append {
        .input-group-text {
          background-color: #ffffff;
          border: none !important;
          border-radius: 0px 10px 10px 0px;
          &:hover {
            box-shadow: none;
          }
        }
      }
      .form-control {
        color: $default-color;
        border: none !important;
        height: 45px;
        border-radius: 10px 0px 0px 10px;
        box-shadow: none;
        //   @include placeholder(){
        //     color: $default-color;
        //   };
      }
    }
  }
  p {
    display: inline-block;
    margin: 0;
    line-height: 1.8em;
    font-size: 1em;
    font-weight: 400;
  }

  &.navbar-absolute {
    position: absolute;
    width: 100%;
    padding-top: 30px;
    // z-index: 1029;
    z-index: 2;
  }

  .documentation & {
    &.fixed-top {
      left: 0;
      width: initial;
    }
  }

  .navbar-wrapper {
    display: inline-flex;
    align-items: center;

    .navbar-minimize {
      padding-right: 10px;

      .btn {
        margin: 0;
      }
    }

    .navbar-toggle {
      .navbar-toggler {
        padding-left: 0;
      }

      &:hover {
        & .navbar-toggler-bar.bar2 {
          width: 22px;
        }
      }
    }
  }

  .newinputs {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 20px;
    //  padding-top: 30px;
    .amer_dropdonfst {
      button#dropdown-basic {
        border-radius: 60px;
        background: var(--White, #fff) !important;
        display: flex;
        width: 145px;
        padding: 13px 15px;
        justify-content: space-between;
        align-items: center;
        color: #4f4e69;
        outline: none !important;
        box-shadow: none !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        border: 1px solid transparent;
      }
      .filyerbyn {
        border-radius: 60px;
        background: var(--White, #fff) !important;
        display: flex;
        width: 145px;
        padding: 13px 15px;
        justify-content: center !important;
        align-items: center;
        color: #4f4e69;
        gap: 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        border: 1px solid transparent;
        outline: none !important;
        box-shadow: none !important;
        &:after {
          display: none;
        }
      }
    }

    .inputoutermain {
      position: relative;
      width: 285px;
      .ambassadorinput {
        width: 100%;
        padding: 12px 15px;
        border-radius: 60px;
        background: var(--White, #fff);
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        outline: none !important;
        border: none !important;

        &::placeholder {
          color: var(--Text, #030229);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          box-shadow: none;
          opacity: 0.5;
        }
      }
      .ambrinputicon {
        position: absolute;
        right: 15px;
        top: 12px;
      }
    }
  }

  .navbar-nav {
    &.navbar-logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 49px;
      top: -4px;
    }

    .nav-link.btn {
      padding: $padding-btn-vertical $padding-btn-horizontal;
      &.btn-lg {
        padding: $padding-large-vertical $padding-large-horizontal;
      }
      &.btn-sm {
        padding: $padding-small-vertical $padding-small-horizontal;
      }
    }

    .nav-link {
      text-transform: uppercase;
      font-size: $font-size-mini;
      padding: $padding-base-vertical $padding-base-horizontal;
      line-height: $line-height-nav-link;
      margin-right: 3px;

      i.fa + p,
      i.nc-icon + p {
        margin-left: 3px;
      }

      i.fa,
      i.nc-icon {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px;
      }

      i.nc-icon {
        top: 4px;
        font-size: 16px;
      }

      &.profile-photo {
        .profile-photo-small {
          width: 27px;
          height: 27px;
        }
      }

      &.disabled {
        opacity: 0.5;
        color: $white-color;
      }
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):active {
      border-radius: $border-radius-small;
      color: $default-color;
    }
  }

  .logo-container {
    width: 27px;
    height: 27px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .navbar-brand {
    text-transform: capitalize;
    font-size: $font-size-large-navbar;
    padding-top: $padding-base-vertical;
    padding-bottom: $padding-base-vertical;
    line-height: $line-height-nav-link;
  }

  .navbar-toggler {
    width: 37px;
    height: 27px;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;

    & .navbar-toggler-bar.navbar-kebab {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      margin: 0 auto;
    }
  }
  .button-dropdown {
    .navbar-toggler-bar:nth-child(2) {
      width: 17px;
    }
  }

  &.navbar-transparent {
    //   background-color: #FAFAFB !important;
    box-shadow: none;
    //   border-bottom: 1px solid #ddd;

    a:not(.dropdown-item):not(.btn) {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #030229;
      &.disabled {
        opacity: 0.5;
        color: $default-color;
      }
    }

    .button-bar {
      background: $default-color;
    }

    .nav-item .nav-link:not(.btn) {
      color: $default-color;
    }
    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):focus:hover,
    .nav-item .nav-link:not(.btn):active {
      color: $primary-color;
    }
  }

  &.bg-white {
    a:not(.dropdown-item):not(.btn) {
      color: $default-color;

      &.disabled {
        opacity: 0.5;
        color: $default-color;
      }
    }

    .button-bar {
      background: $default-color;
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):active {
      color: $info-color;
    }

    .logo-container {
      border: 1px solid $default-color;
    }
  }

  .navbar-collapse {
    .nav-item {
      a {
        font-size: $font-size-base;
      }
    }
  }
}
.amer_dropdonfst {
  button#dropdown-basic {
    border-radius: 60px;
    background: var(--White, #fff) !important;
    display: flex;
    width: 145px;
    padding: 13px 15px;
    justify-content: space-between;
    align-items: center;
    color: #4f4e69;
    outline: none !important;
    box-shadow: none !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border: 1px solid transparent;
  }
  .filyerbyn {
    border-radius: 60px;
    background: var(--White, #fff) !important;
    display: flex;
    width: 145px;
    padding: 13px 15px;
    justify-content: center !important;
    align-items: center;
    color: #4f4e69;
    gap: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border: 1px solid transparent;
    outline: none !important;
    box-shadow: none !important;
    &:after {
      display: none;
    }
  }
}
.bg-default {
  background-color: $default-color !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-info {
  background-color: $info-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-danger {
  background-color: $danger-color !important;
}

.bg-warning {
  background-color: $warning-color !important;
}

.bg-white {
  background-color: $white-color !important;
}

.main-div-nav-head {
  .main-modal-one {
    .daily-profile-modal {
      .daily-profile-modal-inner {
        border-radius: 10px;
        .modal-body-main {
          padding: 50px 40px;
          .main-outter {
            .main-cardssss {
              .form-group {
                .padd-top {
                }
                label {
                  font-family: 'Poppins', sans-serif;
                  font-style: normal !important;
                  font-weight: bold !important;
                  font-size: 16px !important;
                  line-height: 22px !important;
                  color: #000000 !important;
                  padding-top: 15px;
                }
                input {
                  background: #fafafb;
                  border: 1px solid #efefef;
                  box-sizing: border-box;
                  border-radius: 5px;
                  height: 55px;
                  &::placeholder {
                    color: #cdcdcd;
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                  }
                }
                textarea {
                  background: #fafafb;
                  border: 1px solid #efefef;
                  box-sizing: border-box;
                  border-radius: 5px;
                  &::placeholder {
                    color: #cdcdcd;
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                  }
                }
              }
              .button-modal-daily {
                display: flex;
                justify-content: space-between;
                .button-main-daily {
                  margin-top: 30px;
                  outline: none;
                  box-shadow: none;
                  background: linear-gradient(180deg, #133572 0%, #3568c1 100%);
                  border-radius: 10px;
                  width: 100%;
                  height: 50px;
                  border: 1px solid #133572;
                  font-family: 'Poppins', sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  margin-right: 10px;
                  margin-left: 10px;
                  color: #ffffff;
                  transition: 0.4s ease-in-out;
                  &:hover {
                    background: transparent;
                    border: 1px solid #3568c1;
                    color: #3568c1;
                  }
                }
                .button-main-dailys {
                  margin-top: 30px;
                  outline: none;
                  box-shadow: none;
                  background: transparent;
                  border-radius: 10px;
                  width: 100%;
                  height: 50px;
                  border: 1px solid #133572;
                  font-family: 'Poppins', sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  margin-right: 10px;
                  margin-left: 10px;
                  font-size: 14px;
                  line-height: 19px;
                  color: #133572;
                  transition: 0.4s ease-in-out;
                  &:hover {
                    background: linear-gradient(
                      180deg,
                      #133572 0%,
                      #3568c1 100%
                    );
                    border: 1px solid #3568c1;
                    color: #ffffff;
                  }
                }
              }
              p {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px !important;
                line-height: 19px;
                color: #818094 !important;
                padding-bottom: 6px;
              }
              h4 {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                color: #030229;
              }
              .flux-b {
                padding-bottom: 30px;
                h3 {
                  font-family: 'Poppins', sans-serif;
                  font-style: normal;
                  font-weight: bold !important;
                  font-size: 30px !important;
                  line-height: 28px;
                  color: #222222;
                }
              }
              .flux-r {
                padding-left: 39px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .navbar .newinputs .amer_dropdonfst button#dropdown-basic {

    width: 100%;

}
  .navbar .newinputs {

    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.navbar .newinputs .inputoutermain {

  width: 100%;
}
  .main-div-nav-head {
    .modal-body-main {
      padding: 30px 14px !important;
      .main-cardssss .button-modal-daily .button-main-dailys {
        margin-top: 10px !important;
      }
      .main-cardssss .button-modal-daily .button-main-daily {
        margin-top: 10px !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .main-div-nav-head {
    .modal-body-main {
      padding: 30px 3px !important;
      .flux-b {
        padding-bottom: 18px !important;
      }
    }
  }
  .main-div-nav-head
    .main-modal-one
    .daily-profile-modal
    .daily-profile-modal-inner
    .modal-body-main
    .main-outter
    .main-cardssss
    .flux-b
    h3 {
    font-size: 24px !important;
  }
}

.nav-buttonss {
  background: transparent !important;
  border: none;
  color: rgba(128, 128, 128, 0.945);
  border-left: 1px solid rgba(128, 128, 128, 0.945);
}

.choose-filessssss {
  border: 2px dashed rgb(224, 220, 220);
  border-radius: 6px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  height: 250px;
  i {
    color: rgb(168, 166, 166);
    font-size: 36px;
    cursor: pointer;
  }
}

.nsjd {
  position: absolute;
  top: 129px;
  left: 0;
  right: 0;
  width: 10%;
  bottom: 0;
  margin: 0 auto;
}

.sbhjsbd {
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    height: 245px;
    padding: 20px;
    border-radius: 30px;
  }
}
.choose-filessssss:hover .layerss {
  width: 89%;
  height: 210px;
  background-color: grey;
  position: absolute;
  top: 0;
  padding: 20px;
  margin-left: 20px;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-zzz {
  z-index: 10000 !important;
}
.namediv{
  color: #000 ;
font-size: 12px !important;
font-style: normal;
font-weight: 400 !important;
line-height: 120%; /* 14.4px */
padding: 14px;
border-bottom: 1px solid #F2F7FF;
}
.namediv:hover{
  background: #EDF4FF !important;
}
.mydropppp{
  padding: auto !important;
}