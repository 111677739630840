.productss {
  // background: #ffffff;

  // border: 1px solid #f3f7fb;

  .productstable {
    border-radius: 10px;
    padding: 0px 20px;
    border: 1px solid #f3f7fb;
    background: #ffffff;
    // border-radius: 10px;
    table {
      width: 100%;

      thead {
        border-bottom: 1px solid #f3f7fb;

        th {
          cursor: pointer;
          padding: 20px;
          color: #5f6d7e;

          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 138.462% */
          letter-spacing: -0.013px;
          padding-bottom: 18px;

          &:last-child {
            display: flex;
            justify-content: center;
          }

          .arrowdown {
            margin-left: 10px;
            margin-bottom: 2px;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #f3f7fb;
          td {
            cursor: pointer;
              padding: 20px;

            .productimg {
              width: 91px;
              height: 58px;
              background: linear-gradient(180deg, #ffb800 0%, #ff8a00 101.75%),
                linear-gradient(180deg, #3456d0 0%, #2542a6 100%);
              border-radius: 5px;

              .innerimg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .darktext {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }

            .wallet {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              width: 135px;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .droped {
              .dropbtn {
                border-radius: 8px;
                border: 1.5px solid #e1ecff;
                background: var(--White, #fff);
          
                border-radius: 8px;
                padding: 13px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 161px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #000000;
                &:hover {
                    border: 1.5px solid #e1ecff;
                }
              }
              .status {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #000000;
              }
              .pendingcolor {
                color: #ff8300 !important;
              }
              .processingcolor {
                color: #0075ff !important;
              }
              .completecolor {
                color: #00aa21 !important;
              }
            }

            &:last-child {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .products {
    padding: 10px;

    .productstable {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      table {
        width: 900px;
      }
    }
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: unset;
}
