.raffles {
  background: #ffffff;
  border-radius: 10px;
  // padding-left: 30px;
  // padding-right: 30px;
  .ended-content {
    .sub-img {
      width: 62px;
      height: 57px;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .blue-btn {
    background: linear-gradient(89.93deg, #3553d5 50.15%, #0c194e 217.12%);
    border-radius: 60px;
    padding: 12px 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border: none;
  }

  .innertable {
    .namemain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      .namepara {
        color: #5f6d7e;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.013px;
      }
    }
    table {
      width: 100%;
      border-radius: 10px;
      border: 1px solid #f3f7fb;
      background: var(--White, #fff);

      // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      thead {
        padding: 0px;
        th {
          color: var(--Text, #030229);

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.013px;
          cursor: pointer;
          padding: 20px;
          vertical-align: middle;
          border-bottom: 1px solid #f3f7fb;
          white-space: nowrap;
          opacity: 0.7;
        }
      }
      tbody {
        padding: 0px;
        tr {
          td {
            color: #000;

            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.014px;
            border-bottom: 1px solid #f3f7fb;
            background: var(--White, #fff);
            cursor: pointer;
            padding: 20px;
            vertical-align: middle;
            .mainimgdivvid {
              display: flex;
              align-items: center;
              gap: 10px;
              flex: 1 0 0;
              .inerimgdvide {
                width: 32px;
                height: 32px;

                object-fit: cover;
                object-position: center;
                border-radius: 50px;
                img.tableimgginerddvidd {
                  width: 100%;
                  height: 100%;

                  object-fit: cover;
                  object-position: center;
                }
              }
              .tableimgtext {
                color: #2e3646;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.014px;
                white-space: nowrap;
              }
            }

            .detailbtn {
              border-radius: 40px;
              background: #f5f9ff;
              display: flex;
              padding: 9px 37px;
              justify-content: center;
              align-items: center;
              color: #3553d5;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.012px;
              border: none;
              outline: none;
            }
          }
        }
      }
    }
  }
}

.imgslablrtop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.bg-color-raffle {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
}

.addraffles {
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    display: block;
  }
  input {
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    max-width: 700px;
    width: 100%;

    &::placeholder {
      color: #cdcdcd;
    }
  }
  .onlyforchosefiles {
    width: 100%;
    display: flex;
    // width: 659px;
    padding: 15px 412px 15px 10px;
    align-items: center;
  }
  .dropdown-toggle {
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    max-width: 700px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:active {
      background-color: #fafafb !important;
      border: 1px solid #efefef !important;
      color: #000 !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    max-width: 700px;
    width: 100%;
  }
  .upload-img {
    margin-bottom: 60px;
    .upload {
      background: #ffffff;
      border: 1px dashed #c6c6c6;
      border-radius: 5px;
      max-width: 386px;
      width: 100%;
      height: 287px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      label {
        cursor: pointer;
      }
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #cdcdcd;
        label {
          background: linear-gradient(
            89.93deg,
            #3553d5 50.15%,
            #0c194e 217.12%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin: 0;
          display: inline;
          cursor: pointer;
        }
      }
    }
  }
  .option-field {
    margin-bottom: 30px;
  }
  .bottom-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 70px;

    .btn-red {
      background: #c44e3e;
      border: 1px solid #be3817;
      box-sizing: border-box;
      border-radius: 6px;
      color: #fff;
      padding: 8px 15px;
      text-decoration: none;
      font-size: 13px;
      width: 100%;
      &:hover {
        box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3) !important;
      }
    }
  }
  textarea {
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    max-width: 700px;
    width: 100%;
    height: 160px;
    &::placeholder {
      color: #cdcdcd;
    }
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #fafafb !important;
    border: 1px solid #efefef !important;
    color: #000 !important;
  }
  .twice-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 700px;
    width: 100%;
    .option-field {
      width: 48%;
      position: relative;
      .clanderimggonly{
        position: absolute;
        top: 60px;
        right: 20px;
        pointer-events: none;
      }
    }
  }
}
.topCard {
  padding: 22px;
  border: 1px solid #a5afbf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  margin-bottom: 25px;
  .pH {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #a5afbf;
    }
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: #343434;
    }
  }
}
.sidebar-raffle {
  .main-card {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #343434;
      margin-bottom: 15px;
    }
    .ticket {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 33px;
      &:last-child {
        margin-bottom: 0;
      }
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          color: #343434;
        }
        .profile {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .inner-left {
            margin-left: 16px;
            .profile-img {
              width: 44px;
              height: 44px;
              overflow: hidden;
              border-radius: 50%;
              background-color: #3553d5;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .inner-right {
            margin-left: 10px;
            h6 {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 130%;
              color: #343434;
            }
            p {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              color: #a5afbf;
            }
          }
        }
      }
      .right {
        .blue-btn {
          background: linear-gradient(
            89.93deg,
            #3553d5 50.15%,
            #0c194e 217.12%
          );
          border-radius: 60px;
          padding: 6px 13px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
}
.dropdowntoggle {
  background: #fafafb;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-tickets {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  .modal-content {
    background: #ffffff;
    border-radius: 10px;
  }
  .modal-dialog {
    max-width: 574px;
  }
  .modal-header {
    border: none;
    padding: 40px;
    padding-bottom: 0;
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 28px;
      color: #222222;
    }
  
  }
  .modal-body {
    padding: 40px;
    .countsss {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px 5px;
      .count-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 1px solid #f1f4fc;
        border-radius: 50px;
        flex: 0 0 77px;
        padding: 5px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #3456d0;
      }
    }
    .count-active {
      background-color: #2cc84a !important;
      color: #ffffff !important;
    }
  }
}
.addraffles .upload-img .upload {
  position: relative;
}
.addraffles label {
  z-index: 99;
}
.addraffles .upload-img .upload h6 {
  position: relative;
  z-index: 99;
}
.maininput {
  .wrapper {
    border-radius: 5px;
    border: 1px solid #efefef;
    background: #fafafb;
    width: 659px;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 9px 13px;
    cursor: pointer;
    box-shadow: none;
    .gray {
      color: red !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      margin-top: 15px;
      text-align: center;
    }
  }
}

.jjjjjjjjj{
    outline: none;
    box-shadow: none;
    border-radius: 60px;
    background: var(--Buttons-Gradient, linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)) !important;
    width: 170px;
    height: 50px;
    border: 1px solid #3553d5;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff !important;
    margin-right: 20px;
    transition: 0.4s ease-in-out;
}


 .view-tickets {
  z-index: 9999999999999999999999999999999;
}
