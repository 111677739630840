.main-login {
  // position: relative;
  // z-index: -2;
  .mainouterdiv {
    margin-top: 145px;
  }
  .inner-logo {
    img {
      // width: 150px;
    }
  }
  .cmn-tile-style {
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 40px;
    // padding: 30px;

    max-width: 574px;
    width: 100%;
    margin: 45px auto 0px;
    .topinerdiv {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      h2 {
        color: #222 !important;

        font-size: 30px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 28px !important;
      }

      .loginpara {
        color: #636363 !important;
        border-bottom: 1px solid #e6e6e6;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 160% !important;
        padding-bottom: 25px;
        margin-bottom: 40px;
        width: 100%;
      }
    }
    .butmdivmain {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      gap: 50px;
      label.form-check-label {
        color: #222;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
      .forgetpasssed {
        background: var(
          --Buttons-Gradient,
          linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        margin-top: 15px;
      }
      .formcheck_newwwwws {
        label.checkBox {
          display: flex;
          align-items: center;
          gap: 9px;
        }
      }
    }
    label {
      //   font-family: "Poppins", sans-serif;
      //   font-style: normal !important;
      //   font-weight: bold !important;
      //   font-size: 16px !important;
      //   line-height: 22px !important;
      //   color: #000000 !important;
      //   padding-top: 30px;
    }
    // input {
    //   border-radius: 10px;
    //   border: 1.5px solid #e1ecff;
    //   background: var(--White, #fff) !important;
    //   display: flex;
    //   padding: 18px 10px 18px 20px;
    //   align-items: center;
    //   box-shadow: none !important;

    //   &::placeholder {
    //     color: #cad3e2;
    //     font-family: Poppins;
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 500;
    //     line-height: normal;
    //     // border-radius: 10px;
    //     // border: 1.5px solid #e1ecff;
    //     // background: var(--White, #fff);
    //   }
    // }
  }

  .inputErrors {
    color: red;
  }
}
.padnggleft{


  padding-right: 50px !important;
}
.MuiBackdrop-root.loader {
  z-index: 20000 !important;
}

.MuiCircularProgress-root {
  width: 50px !important;
  height: 50px !important;
  color: white;
}

@media (max-width: 600px) {
  .main-login .mainouterdiv {
    margin-top: 60px;
  }
  .main-login .cmn-tile-style {
    padding: 20px;
    margin: 25px auto 0px;
  }
  .main-login .cmn-tile-style .butmdivmain {
    gap: 35px;
  }
  .btn-common {
    padding: 10px;
  }
}
