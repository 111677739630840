.main-login-auth {
  // position: relative;
  // z-index: -2;
  margin-top: 145px;
  .inner-logo {
    img {
      // width: 150px;
    }
  }
  .cmn-tile-style {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 71px;
    .fsthndg {
      color: var(--Text, #030229);

      font-size: 40px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .secpara {
      color: var(--Text, #030229) !important;
      text-align: center;

      font-size: 16px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
      max-width: 825px;
      width: 100%;
      margin: 0px auto;
    }
    .seclasthngd {
      color: var(--Text, #030229) !important;

      font-size: 40px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .seclastparss {
      color: var(--Text, #030229) !important;
      text-align: center;

      font-size: 16px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
      max-width: 690px;
      width: 100%;
      margin: 0px auto;
    }
    h6 {
      font-weight: normal;
      color: #636363;
    }
    .text-center.crqscntrdiv {
      margin: 30px;
    }

    label {
      font-family: "Poppins", sans-serif;
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 22px !important;
      color: #000000 !important;
      padding-top: 30px;
    }
    input {
      box-sizing: border-box;
      border-radius: 10px;
      border: 1.5px solid #e1ecff;
      background: var(--White, #fff);
      box-shadow: none;
      max-width: 1040px;
      width: 100%;
      margin: 0px auto;
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 17px 25px;
      &::placeholder {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .textrightlast {
    margin-top: 62px;
    margin-bottom: 62px;
    display: flex;
    gap: 20px;
    justify-content: end;
    button.btn-commonseccfst {
      display: flex;
      padding: 17px 51px 18px 51px;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      border: 1px solid var(--Buttons-Gradient, #3553d5);
      background: var(
        --Buttons-Gradient,
        linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .btn-commonsecc {
      border-radius: 60px;
      background: var(
        --Buttons-Gradient,
        linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
      );
      display: flex;
      padding: 17px 52px 18px 53px;
      justify-content: center;
      align-items: center;
      color: var(--White, #fff);
      text-align: center;
      border: none;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .inputErrors {
    color: red;
  }
}

.MuiBackdrop-root.loader {
  z-index: 20000 !important;
}

.MuiCircularProgress-root {
  width: 50px !important;
  height: 50px !important;
  color: white;
}
@media (max-width: 600px) {
  .main-login-auth {
    margin-top: 50px;
  }
  .main-login-auth .cmn-tile-style .fsthndg {
    font-size: 25px !important;
  }
  .main-login-auth .cmn-tile-style .secpara {
    font-size: 14px !important;
  }
  .main-login-auth .cmn-tile-style {
    gap: 15px;
    margin-top: 40px;
  }
  .main-login-auth .cmn-tile-style .seclasthngd {
    font-size: 25px !important;
  }

  .main-login-auth .cmn-tile-style .seclastparss {
    font-size: 14px !important;
  }
  .main-login-auth .cmn-tile-style input {
    padding: 10px 25px;
  }
  .main-login-auth .textrightlast {
    margin-top: 40px;
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }
}
