.activecustomclasss .iconns {
    path {
        fill: #3654d6 !important;
    }
}

.new-drop-down{
    svg{
        margin-right: 12px;
    }
}

.card-body{
    padding: 0px;
}