@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

.users {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.content {
  .nav-tabs .nav-link.active {
    padding-left: 2px !important;
    border-bottom: 3px solid #3568c1 !important;
    padding-right: 0px;
    color: #000 !important;

    font-size: 16px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
  }

  .nav-tabs .nav-link {
    color: rgba(0, 0, 0, 0.35) !important;
    border: none !important;
    border-bottom: 3px solid transparent !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600 !important;
    // margin-right: 26px;
  }

  .set_set_search_bar {
    position: relative;
    // right: -636px;
    // right: -74%;
    // width: 20%;
    width: 230px;
    // top: -90px;
    margin-left: 25px;
    z-index: 222;
    // margin: 0 auto;
    /* right: -64%; */
    height: 42px;
    // top: -34px;
    padding-left: 23px;
    border-radius: 100px;
    border: 1px solid #d6d6d6;
  }

  .inputtttt {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 230px;
    margin-left: 10px;
    // right: -636px;
    // right: -74%;
    // width: 20%;
    // top: -70px;
    // z-index: 222;
    // padding-left: 23px;

    .fgfhf {
      margin-bottom: 20px;
      border-radius: 100px;
      border: 1px solid #d6d6d6;
      /* right: -64%; */
      padding-left: 10px;
      padding-right: 10px;
      height: 42px;
    }
  }

  .MinMax {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 230px;
    margin-left: 10px;

    .fgfhffg {
      margin-bottom: 20px;
      border-radius: 100px;
      border: 1px solid #d6d6d6;
      /* right: -64%; */
      padding-left: 20px;
      padding-right: 14px;
      height: 42px;
    }
  }
}

.user-details {
  .upper-detail-page {
    padding: 45px 0px;

    .button-red {
      outline: none;
      border: none;
      box-shadow: none;
      padding: 13px 30px;
      background: linear-gradient(180deg, #e72d1d 0%, #e71d36 100%);
      border-radius: 10px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      transition: 0.3s ease-in-out;
      font-size: 14px;
      color: #ffffff;
      line-height: 19px;

      &:hover {
        box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3);
      }
    }

    .button-green {
      outline: none;
      border: none;
      box-shadow: none;
      padding: 13px 30px;
      background: linear-gradient(180deg, #2ec735 0%, #24e71d 100%);
      border-radius: 10px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      transition: 0.3s ease-in-out;
      font-size: 14px;
      color: #ffffff;
      line-height: 19px;

      &:hover {
        box-shadow: 0px 10px 14px rgba(33, 42, 56, 0.3);
      }
    }

    .feildss {
      margin-bottom: 30px;

      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px !important;
        line-height: 19px;
        color: #818094;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px !important;
        line-height: 27px;
        color: #030229;
      }

      .email {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px !important;
        line-height: 27px;
        color: #030229;
      }
    }

    .imsge-d {
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 11px 7px;

      img {
        width: 100%;
      }

      .modal-main {
        border-radius: 1.3rem;
      }
    }
  }

  .lower-upper-detail {
    overflow-y: auto;

    h4 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #222222;
      padding-left: 0.75rem;
      padding-top: 0.75rem;
    }
  }

  .detail-card {
    text-align: center;

    p {
      margin-bottom: 0px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #030229;
    }
  }
}

.part-right {
  padding: 30px 25px;

  border-radius: 10px;
  border: 1px solid #f3f7fb;
  background: var(--White, #fff);

  // display: flex;
  //   gap: 46px;
  //   flex-direction: column;
  .topdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .tommodimg {
      border-radius: 170px;
      display: flex;

      justify-content: center;
      align-items: center;
      background: #3054d3;
      object-fit: cover;
      object-position: center;
      max-width: 150px;
      height: 150px;
      flex-shrink: 0;
      width: 100%;

      .modinerimg {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .hadingandbtnn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      h6.damonholland {
        color: #2e3646;
        text-align: center;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.022px;
      }

      .btn-commonsss {
        border-radius: 40px;
        background: #3553d5;
        display: flex;
        padding: 11px 37px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        color: var(--White, #fff);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.012px;
        border: none;
      }
    }
  }

  .rightsecdiv {
    display: flex;
    /* width: 319px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 43px;
    margin-top: 46px;

    .modfsrflex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .fsteft {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }

      .namefullletf {
        color: var(--Text, #030229);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .walletaddresss {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: var(--Text, #030229);
        white-space: nowrap;
        width: 97px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .usernnamee {
        color: #818094;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      .fsteftsec {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
      }
    }
  }

  .mod_bodydivsec {
    // display: flex;
    // padding: 20px;
    // flex-direction: column;
    // align-items: flex-start;
    // gap: 25px;
    // border-radius: 10px;
    // background: #f7f8fd;
    // margin-top: 20px;
    // margin-bottom: 20px;
    .modfsrflex {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .fsteft {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        gap: 10px;

        .usernnamee {
          color: #818094;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }

        .namefullletf {
          color: #030229;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0.14px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .fsteftsec {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 50%;
        gap: 10px;

        h6.usernnamee {
          color: #818094;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }

        .namefullletf {
          color: #030229;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0.14px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}

.uncomplete {
  color: red !important;
}

.main-outer-p {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.main-switch-nn .custom-control {
  margin-top: 0px;
  padding-left: 40px !important;
}

.main-switch-nn .custom-switch {
  .pd-right {
    padding-right: 47px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 24px;
    color: #030229;
  }
}

.main-switch-nn .custom-control-input:checked ~ .custom-control-label::before {
  background: #2CC84A;
  border: 1px solid #2CC84A;
  box-shadow: none;
  // border: 1px solid #fff;
  width: 38px;
  height: 20.7px;
  box-shadow: none;
}

.main-switch-nn .custom-control-label::before {
  background: #2CC84A;
  border: 1px solid #2CC84A;
  width: 38px;
  height: 20.7px;
  box-shadow: none;
  // border: 1px solid #BEBEBE;
  box-shadow: none;
}

.main-switch-nn .custom-control-label::after {
  background-color: #BEBEBE;
  width: calc(1rem - 1px);
  height: calc(1rem - 1px);
  box-shadow: none;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after{
  transform: translateX(1.1rem);
}
// pagination

.pagination {
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  border-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  color: #fff;
}

.pagination > li > a {
  border: 1px solid linear-gradient(180deg, #133572 0%, #3568c1 100%);
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  border-color: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #ffffff;
  border: 1px solid linear-gradient(180deg, #133572 0%, #3568c1 100%);
  border-radius: 4px;
  font-size: 16px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 4px;
}

.fa-arrow-right:before {
  content: "\f061";
  color: white;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
  background: linear-gradient(180deg, #133572 0%, #3568c1 100%);
  color: white;
  margin-right: 5px;
}

.fa-arrow-left:before {
  content: "\f060";
  color: white;
}

.text-head1 {
  img {
    width: 294px;
    height: 262px;
    border-radius: 24px;
  }
}

.main-name {
  width: 100px;
}

//   li.active a {
//     background-color: #0366d6;
//     border-color: transparent;
//     color: white;
//     min-width: 32px;
//   }

.pagination > li > a,
.pagination > li > span {
  color: #020202 !important;
  border: 1px solid linear-gradient(180deg, #133572 0%, #3568c1 100%);
  border-radius: 4px;
  font-size: 16px;
}

.main-text-feild-head {
  position: relative;
  // right: -636px;
  // right: -6%;
  // width: 25%;
  // top: 0px;
  // z-index: 222;
  // margin: 0 auto;
  /* right: -64%; */
  height: 42px;
  // top: -34px;
  margin-left: 25px;

  // border-radius: 100px;
  // border: 1px solid #d6d6d6;
  label {
    font-size: 16px;
    margin-left: 10px;
  }

  .button-list {
    .butt {
      background-color: transparent;
      border: 1px solid #d6d6d6;
      width: 230px;
      height: 42px;
      // margin-top: -139px;
      background-color: white;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dropdown-menu {
      width: 100%;
    }
  }
}

.sjndsjdnsjn {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 769px) {
  // .content{
  //     .set_set_search_bar{
  //         position: relative;
  //         // right: -636px;
  //         // right: -64%;
  //         right: 0px;
  //         width: 100%;
  //         top: -33px;
  //         z-index: 222;
  //         margin: 0 auto;
  //         /* right: -64%; */
  //         height: 42px;
  //         // top: -34px;
  //         padding-left: 23px;
  //         border-radius: 100px;
  //         color: rgba(0, 0, 0, 0.35) !important;
  //         }
  // }
}

.namessss {
  position: relative;

  input {
  }

  .agsvahvs {
    position: absolute;
    right: 16px;
    top: 6px;
    z-index: 11111;
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 50%;
    background-color: rgb(5, 67, 149);

    i {
      color: #fff;
    }
  }
}

// .main-text-feild-head2{
//     position: relative;
//     // right: -636px;
//     right: -1%;
//     width: 25%;
//     top: 0px;
//     z-index: 222;
//     margin: 0 auto;
//     /* right: -64%; */
//     height: 42px;
//     // top: -34px;
//     padding-left: 23px;
//     // border-radius: 100px;
//     // border: 1px solid #d6d6d6;
//     label{
//         font-size: 16px;
//         margin-left: 10px;
//     }

// }

@media (max-width: 1199px) {
  .main-text-feild-head {
    margin-left: 12px;
  }

  .content .set_set_search_bar {
    margin-left: 12px;
  }
}

@media (max-width: 600px) {
  .content .inputtttt {
    width: 100%;
    margin-left: 0px;
  }

  .content .MinMax {
    width: 100%;
    margin-left: 0px;
  }

  .main-text-feild-head {
    width: 100%;
    margin-left: 0px;
  }

  .main-text-feild-head .button-list .butt {
    width: 100%;
  }

  .namessss {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .content .set_set_search_bar {
    width: 100%;
    margin-left: 0px;

    input {
      width: 100%;
    }
  }
}

.maintableauser {
  border-radius: 10px;
  // border: 1px solid #f3f7fb;
  background: var(--White, #fff);
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  overflow: hidden;

  .claimpillss {
    .amberpillsouter {
      max-width: 286px;

      .nav-item {
        width: 49% !important;
      }
    }
  }

  .claimpillss {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ambnavlastbtn {
      display: flex;
      /* width: 402px; */
      align-items: center;
      gap: 16px;

      .alowgreen {
        padding: 12px 24px;

        border-radius: 60px;
        background: linear-gradient(180deg, #15fa2c 0%, #0dd721 100%);
        color: var(--White, #fff);
        text-align: center;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: antiquewhite;
      }

      button.rejetred {
        padding: 12px 24px;

        border-radius: 60px;
        background: var(
          --Red-Gradient,
          linear-gradient(180deg, #f04914 0%, #d0390a 100%)
        );
        color: var(--White, #fff);
        text-align: center;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: antiquewhite;
      }
    }
  }

  .maintablepills {
    padding: 20px 13px;
    border-bottom: 1px solid #f3f7fb;
    background: var(--White, #fff);

    .amberpillsouter {
      border-radius: 8px;
      border: 1px solid rgba(52, 86, 208, 0.1);
      padding: 5px;
      max-width: 423px;
      width: 100%;
      align-items: center;
      gap: 5px;

      .nav-link.active,
      .nav-pills .show > .nav-link {
        background: #f8e7e2 !important;

        font-weight: 600 !important;
      }

      .amberitempils {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 32%;

        .nav-link {
          color: var(--Primary-Color, #3456d0) !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          text-transform: capitalize;
          margin: 0px;
          padding: 7px 0px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .lowertabsss {
    display: flex;
    padding: 13px 20px;
    align-items: center;
    gap: 16px;
    // margin-bottom: 30px;
    background: var(--White, #fff);
    border-bottom: 1px solid #e6e9ec;

    .amberpillsoutersss {
      display: flex;
      max-width: 523px;
      width: 100%;
      align-items: center;
      border-radius: 60px;
      border: 1px solid #f0f6ff;
      background: var(--White, #fff);

      .nav-item {
        width: 33.33%;
      }

      a.inerambss {
        display: flex;
        padding: 13px 0px;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 60px;
        color: var(--Primary-Color, #3456d0);
        text-align: center;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        /* 15.6px */
      }
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #fff !important;

      background: var(--Primary-Color, #3456d0) !important;
    }
  }

  .innertable_user {
    .namemain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .namepara {
        color: #5f6d7e;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.013px;
      }
    }

    table {
      width: 100%;

      thead {
        padding: 0px;

        th {
          color: #5f6d7e;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.013px;
                cursor: pointer;
              padding: 20px;
          vertical-align: middle;
          border-bottom: 1px solid #f3f7fb;
          white-space: nowrap;
        }
      }

      tbody {
        padding: 0px;

        tr {
          td {
            .eleipiess {
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 180px;
              display: block;
              white-space: pre-wrap;
              width: 100%;
              cursor: pointer;
          
            }

            .mainimgdiv {
              display: flex;

              align-items: center;
              gap: 16px;

              .inerimgd {
                width: 32px;
                height: 32px;
                border-radius: 30px;
                object-fit: cover;
                object-position: center;

                .tableimgginer {
                  width: 100%;
                  height: 100%;
                  border-radius: 30px;
                  object-fit: cover;
                  object-position: center;
                }
              }

              .tableimgtext {
                color: #2e3646;

                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.014px;
                white-space: nowrap;
              }
            }

            color: #5f6d7e !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.014px;
            border-bottom: 1px solid #f3f7fb;
            background: var(--White, #fff);
                  cursor: pointer;
              padding: 20px;
            vertical-align: middle;

            .detailbtn {
              border-radius: 40px;
              background: #f5f9ff;
              display: flex;
              padding: 9px 37px;
              justify-content: center;
              align-items: center;
              color: #3553d5;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.012px;
              border: none;
              outline: none;
            }
          }
        }
      }
    }
  }

  .Paginationlattable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 24px;

    .pagination {
      width: 100%;
      // justify-content: space-between;
    }

    .leftpigbtn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      padding: 0px;
      margin: 0px;
      color: #5f6d7e;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px;
      letter-spacing: -0.014px;
      border: none;
      background-color: transparent;
    }

    .pagination {
      position: relative;
      padding: 0px;
      margin: 0px;
      gap: 2px;

      // .page-item.disabled {
      //   position: absolute;
      //   left: 0px;
      // }

      .page-item.active .page-link {
        border-radius: 5px;
        background: #e6e9ec;
      }

      .page-item {
        width: fit-content;
        // min-width: 40px;
        height: 40px;
        border-radius: 5px;
        padding: 0px 10px;

        .page-link {
          display: flex;
          // width: 40px;
          // height: 40px;
          // border-radius: 5px;
          // padding: 0px 16px;
          padding: 0px;
          justify-content: center;
          align-items: center;
          border: none !important;
          color: #5f6d7e !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          // letter-spacing: 3px;
          box-shadow: none !important;
        }
      }
    }
  }
}

// USERDETAILS CSS ONLY
.user-details {
  .mainimgdivvid {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;

    .inerimgdvide {
      width: 82px;
      height: 58px;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;

      img.tableimgginerddvidd {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .mainimgdiv {
    display: flex;

    align-items: center;
    gap: 16px;

    .inerimgd {
      width: 32px;
      height: 32px;
      border-radius: 30px;
      object-fit: cover;
      object-position: center;

      .tableimgginer {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        object-fit: cover;
        object-position: center;
      }
    }

    .tableimgtext {
      color: #2e3646;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.014px;
      white-space: nowrap;
    }
  }

  .sectoaldiv {
    border-radius: 10px;
    border: 1px solid #f3f7fb;
    background: var(--White, #fff);
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 10px;

    justify-content: space-between;
    margin-bottom: 25px;

    .totalwalet {
      color: #818094;

      font-size: 14px !important;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }

    .waltdigts {
      color: var(--Primary-Color, #3456d0);

      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }

  .maintopdiv {
    border-radius: 10px;
    border: 1px solid #f3f7fb;
    background: var(--White, #fff);
    display: flex;
    // max-width: 1135px;
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start !important;
    width: 100%;

    .inerimgdss {
      width: 46px;
      height: 46px;
      object-fit: cover;
      object-position: center;
      border-radius: 46px;
      border: 1.5px solid var(--Buttons-Gradient, #3553d5);
      background: lightgray 50% / cover no-repeat;

      img.tableimgginerss {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        object-fit: cover;
        object-position: center;
      }
    }

    .inertopcolmm {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .Usernnvame {
        color: #818094;

        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      .ericccrown {
        color: var(--Text, #030229);

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }
  }

  .lowertabsss {
    display: flex;
    padding: 13px 0px;
    align-items: center;
    gap: 16px;
    // margin-bottom: 30px;
    background: var(--White, #fff);
    border-bottom: 1px solid #e6e9ec;
    margin: 25px 0px;
    border-radius: 10px;
    border: 1px solid transparent;
    .amberpillsoutersss {
      // display: flex;
      // max-width: 523px;
      // width: 100%;
      // align-items: center;
      // border-radius: 60px;
      // border: 1px solid #f0f6ff;
      // background: var(--White, #fff);

      display: flex;
      // max-width: 1135px;
      width: 100%;

      align-items: center;
      gap: 25px;
      border-radius: 60px;
      border: 1px solid #f0f6ff;
      background: var(--White, #fff);
      margin: 5px;
      justify-content: space-between;

      .nav-item {
        width: 12.33%;
      }

      // a.inerambss {
      //   display: flex;
      //   padding: 13px 0px;
      //   width: 100%;
      //   justify-content: center;
      //   align-items: center;
      //   gap: 10px;
      //   flex: 1 0 0;
      //   border-radius: 60px;
      //   color: var(--Primary-Color, #3456d0) !important;
      //   text-align: center;

      //   font-size: 12px;
      //   font-style: normal;
      //   font-weight: 500;
      //   line-height: 130%; /* 15.6px */

      //   &:hover {
      //     background: #3456d0 !important;
      //   }
      // }
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #fff !important;

      background: var(--Primary-Color, #3456d0) !important;
    }
  }

  .maintablea_user-detailss {
    .namemain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .namepara {
        color: #5f6d7e;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.013px;
      }
    }

    table {
      width: 100%;
      border-radius: 10px;
      border-bottom: 1px solid #f3f7fb;
      background: var(--White, #fff);

      /* Shadow/XSM */
      // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      thead {
        padding: 0px;

        th {
          color: #5f6d7e;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.013px;
                cursor: pointer;
              padding: 20px;
          vertical-align: middle;
          border-bottom: 1px solid #f3f7fb;
          white-space: nowrap;
        }
      }

      tbody {
        padding: 0px;

        tr {
          td {
            color: #5f6d7e !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.014px;
            // border-bottom: 1px solid #f3f7fb;
            background: var(--White, #fff);
                  cursor: pointer;
              padding: 20px;
            vertical-align: middle;

            .inerimgddd {
              width: 40px;
              height: 40px;
              border-radius: 30px;
              object-fit: cover;
              object-position: center;

              .tableimgginerdd {
                width: 100%;
                height: 100%;
                border-radius: 30px;
                object-fit: cover;
                object-position: center;
              }

              .tableimgginerddimgrafal {
                width: 100%;
                height: 100%;
                border-radius: 2px;
                object-fit: cover;
                object-position: center;
              }
            }

            .detailbtn {
              border-radius: 40px;
              background: #f5f9ff;
              display: flex;
              padding: 9px 37px;
              justify-content: center;
              align-items: center;
              color: #3553d5;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: -0.012px;
              border: none;
              outline: none;
            }
          }
        }
      }
    }
  }

  .Paginationlattable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 24px;

    .leftpigbtn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      padding: 0px;
      margin: 0px;
      color: #5f6d7e;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px;
      letter-spacing: -0.014px;
      border: none;
      background-color: transparent;
    }

    .pagination {
      margin: 0px;
      padding: 0px;
      gap: 2px;

      .page-item.active .page-link {
        border-radius: 5px;
        // background: #e6e9ec;
      }

      .page-item {
        .page-link {
          display: flex;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          padding: 0px 16px;
          justify-content: center;
          align-items: center;
          border: none !important;
          color: #5f6d7e !important;
          font-size: 14px;
          font-style: normal;
          // font-weight: 900 !important;
          line-height: 20px;
          letter-spacing: -0.014px;
          box-shadow: none !important;
        }
      }
    }
  }

  .maintablea_usedetails {
    border-radius: 10px;
    border: 1px solid #f3f7fb;
    background: var(--White, #fff);
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    overflow: hidden;

    .claimpillss {
      .amberpillsouter {
        max-width: 286px;

        .nav-item {
          width: 49% !important;
        }
      }
    }

    .claimpillss {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ambnavlastbtn {
        display: flex;
        /* width: 402px; */
        align-items: center;
        gap: 16px;

        .alowgreen {
          padding: 12px 24px;

          border-radius: 60px;
          background: linear-gradient(180deg, #15fa2c 0%, #0dd721 100%);
          color: var(--White, #fff);
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: antiquewhite;
        }

        button.rejetred {
          padding: 12px 24px;

          border-radius: 60px;
          background: var(
            --Red-Gradient,
            linear-gradient(180deg, #f04914 0%, #d0390a 100%)
          );
          color: var(--White, #fff);
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: antiquewhite;
        }
      }
    }

    .maintablepills_usedetails {
      padding: 20px 13px;
      border-bottom: 1px solid #f3f7fb;
      background: var(--White, #fff);

      .newclanadeflaxdiv {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button.clanderbtnn {
          border-radius: 8px;
          border: 1.5px solid #e1ecff;
          background: var(--White, #fff);
          display: flex;
          padding: 11px;
          align-items: center;
          gap: 15px;
        }
      }

      .amberpillsouterdetails {
        border-radius: 8px;
        border: 1px solid rgba(52, 86, 208, 0.1);
        padding: 5px;
        // max-width: 423px;
        max-width: 100%;
        width: 841px;
        align-items: center;
        gap: 5px;

        .nav-link.active,
        .nav-pills .show > .nav-link {
          background: #e2e7f8 !important;
        }

        .amberitempils {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          width: 16%;
          white-space: nowrap;

          .nav-link {
            color: var(--Primary-Color, #3456d0) !important;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            text-transform: capitalize;
            margin: 0px;
            padding: 7px 0px;
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    .lowertabsss {
      display: flex;
      padding: 13px 20px;
      align-items: center;
      gap: 16px;
      // margin-bottom: 30px;
      background: var(--White, #fff);
      border-bottom: 1px solid #e6e9ec;

      .amberpillsoutersss {
        display: flex;
        max-width: 523px;
        width: 100%;
        align-items: center;
        border-radius: 60px;
        border: 1px solid #f0f6ff;
        background: var(--White, #fff);

        .nav-item {
          width: 45%;
          margin: 5px;
        }

        a.inerambss {
          display: flex;
          padding: 13px 0px;
          width: 100%;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          border-radius: 60px;
          color: var(--Primary-Color, #3456d0);
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          /* 15.6px */
        }
      }

      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        color: #fff !important;

        background: var(--Primary-Color, #3456d0) !important;
      }
    }

    .maintablea_user-detailss {
      .namemain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        .namepara {
          color: #5f6d7e;
          font-size: 13px !important;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.013px;
        }
      }

      table {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #f3f7fb;
        background: var(--White, #fff);

        // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        thead {
          padding: 0px;

          th {
            color: #5f6d7e;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.013px;
                  cursor: pointer;
              padding: 20px;
            vertical-align: middle;
            border-bottom: 1px solid #f3f7fb;
            white-space: nowrap;
          }
        }

        tbody {
          padding: 0px;

          tr {
            td {
              color: #5f6d7e !important;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.014px;
              border-bottom: 1px solid #f3f7fb;
              background: var(--White, #fff);
                    cursor: pointer;
              padding: 20px;
              vertical-align: middle;

              .detailbtn {
                border-radius: 40px;
                background: #f5f9ff;
                display: flex;
                padding: 9px 37px;
                justify-content: center;
                align-items: center;
                color: #3553d5;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: -0.012px;
                border: none;
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
.eleipiessnewchat{
  overflow: hidden;
  text-overflow: ellipsis;

  display: block;
  white-space: pre-wrap;
  padding: 10px;
  width: 100%;
  max-width: 400px;

}

.onlyforchatpadng{

  padding-right: 70px !important;
  padding-top: 20px !important;
  padding-left: 20px !important;
}
// Ticket Dideils

.main-ticketouterdiv {
  display: flex;
  padding: 25px 25px 10px 25px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;

  border-radius: 10px;
  border: 1px solid #f3f7fb;
  background: var(--White, #fff);
  gap: 35px;

  .ticket_topdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 100%;

    .ttiiitle {
      color: #000;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    .plundered {
      color: #030229;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    .ddescription {
      color: #030229;

      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    .ddescriptiondsa {
      color: #000;

      font-size: 18px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 18px */
    }
  }

  .mainimg-and-commets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .imgssddand-commets {
      display: flex;
      gap: 15px;
      align-items: self-start;

      .mainimgdiv {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        gap: 16px;

        .inerimgddd {
          width: 40px;
          height: 40px;
          border-radius: 30px;
          object-fit: cover;
          object-position: center;

          img.tableimgginerdd {
            width: 100%;
            height: 100%;
            border-radius: 30px;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      .img-and-commets {
        display: flex;
        max-width: 588px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-direction: column;
        border-radius: 5px 15px 15px 15px;
        background: #f5f6fd;

        .onlytimeanddadt {
          color: #030229;

          font-size: 12px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
      }

      .onlytimeanddadt {
        color: #030229;

        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }

  .lastinerinput {
    position: relative;
    width: 100%;

    .msgsinputonly {
      border-radius: var(--16, 16px);
      background: var(--Primary-Light, #f7f9fb);
      padding: 8px 10px 8px 20px;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      width: 100%;
      border: none;
      height: 58px;

      &::placeholder {
        color: #cdcdcd;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }

    .inerinputfstbtn {
      background: transparent;
      border: none;
      outline: none;
      position: absolute;
      top: 15px;
      right: 60px;
      padding: 0px;
      margin: 0px;
    }

    .inerinputlastbtn {
      border-radius: 8px;
      background: #3654d6;
      border: none;
      outline: none;
      padding: 10px;
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }
}

.userlastmodal {
  .userlastmod {
    .fstdivmod {
      .fstlabble {
        width: 100%;
        color: var(--Text-Black, #222);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.1px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        align-self: stretch;

        .dropdown.amer_dropdonfstssttss {
          width: 100%;
        }

        button#dropdownMenuButton {
          border-radius: 5px;
          border: 1px solid #efefef;
          background: #fafafb;
          padding: 15px 20px;
          width: 100%;
          display: flex;
          /* padding: 15px 20px; */
          justify-content: space-between;
          align-items: center;
          outline: none;
          box-shadow: none;
        }
      }

      textarea.form-control.inercallusertextarea {
        display: flex;
        padding: 20px;
        align-items: flex-start;
        /* gap: 10px; */
        /* align-self: stretch; */
        border-radius: 5px;
        border: 1px solid #efefef;
        background: #fafafb;
        color: #000000;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: #cdcdcd;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .userlastmodxxxx {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    .xxxanceldddss {
      padding: 16px 59px;
      border-radius: 60px;
      border: 1px solid #3654d6;
      background: var(--White, #fff);
      color: #3654d6;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 100%;
      outline: none;
      // border: none;
      box-shadow: none;
    }

    .ccancafssadasdsdel {
      border-radius: 60px;
      background: #3654d6;
      padding: 16px 59px;
      color: var(--White, #fff);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 100%;
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
}

@media (max-width: 1199px) {
  .part-right {
    margin-top: 20px;
  }

  .user-details .maintopdiv {
    flex-wrap: wrap;

    gap: 17px;
  }

  .user-details .maintopdiv .inerimgdss {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 800px) {
  .user-details .lowertabsss .amberpillsoutersss {
    white-space: nowrap;
    overflow-x: scroll;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .user-details .lowertabsss .amberpillsoutersss .nav-item {
    width: 22.33%;
  }

  .user-details .lowertabsss .amberpillsoutersss a.inerambss {
    padding: 12px 39px;
    width: 100%;

    font-size: 10px;
  }

  .user-details
    .maintablea_usedetails
    .maintablepills_usedetails
    .amberpillsouterdetails {
    white-space: nowrap;
    overflow-x: scroll;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .user-details .maintablea_usedetails .maintablepills_usedetails {
    padding: 10px 0px;
  }

  .user-details
    .maintablea_usedetails
    .maintablepills_usedetails
    .amberpillsouterdetails
    .amberitempils {
    width: 33%;
  }

  .user-details
    .maintablea_usedetails
    .maintablepills_usedetails
    .amberpillsouterdetails
    .amberitempils
    .nav-link {
    padding: 7px 5px;
  }
}
