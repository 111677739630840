.subscription{
    .sub-img{
        width: 91px;
        height: 58px;
        border-radius: 5px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .blue-btn{
        background: linear-gradient(89.93deg, #3553D5 50.15%, #0C194E 217.12%);
        border-radius: 60px;
        padding: 12px 40px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
        border: none;
    }
}


.add-subscription{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
    h6{
        cursor: pointer;
        position: relative !important;
        z-index: 999;
    }
    label{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 12px;
        display: block;
        cursor: pointer;
    position: relative !important;
    z-index: 999;
    }
    input{
        background: #FAFAFB;
        border: 1px solid #EFEFEF;
        border-radius: 5px;
        padding: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #9A9AA9;
        max-width: 700px;
        width: 100%;
        &::placeholder{
            color: #9A9AA9;
        }
    }
    .upload-img{
        margin-bottom: 60px;
        .upload{
            background: #FFFFFF;
            border: 1px dashed #C6C6C6;
            border-radius: 5px;
            max-width: 386px;
            width: 100%;
            height: 287px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            label{
                cursor: pointer;
            }
            h6{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #CDCDCD;
                label{
                    background: linear-gradient(89.93deg, #3553D5 50.15%, #0C194E 217.12%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    margin: 0;
                    display: inline;
                    cursor: pointer;
                }
            }
        }
    }
    .option-field{
        margin-bottom: 30px;
    }
    .bottom-btn{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-top: 70px;
        button{
            background: #FFFFFF;
            border: 1px solid #3568C1;
            border-radius: 60px;
            padding: 13px 45px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #3553D5;
            &:hover{
                background: linear-gradient(89.93deg, #3553D5 50.15%, #0C194E 217.12%);
                color: #FFFFFF;
            }
        }
    }
}
.imgProfile{
    position: absolute;
    z-index: 999;
    width: 100%;
    /* height: 25px; */
    object-fit: cover;
    object-position: center;
    // height: 287px;
}
.addMore {
    background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
    border-radius: 10px;
    outline: none;
    border: none;
    width: 151px;
    height: 45px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 20px;
    transition: 0.4s ease-in-out;
}