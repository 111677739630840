.dropup .dropdown-toggle:after, .dropdown .dropdown-toggle:after {
    margin-left: 3px;
}

.namediv{
  color: #000 ;
font-size: 12px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 120%; /* 14.4px */
padding: 14px;
border-bottom: 1px solid #F2F7FF;
}
.namediv:hover{
  background: #EDF4FF !important;
}
.mydropppp{
  padding: 0px !important;

}

.mydropppp.dropdown-menu.show {
  border-radius: 8px;
  border: 1.5px solid #E1ECFF;
  background: var(--White, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  z-index: 6666;

}