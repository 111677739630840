// .addbanner{
//     width: 100%;
//     min-height: 100%;

//     .inner-submit-lower-div{
//         padding-top: 30px;
//         padding-bottom: 30px;
//         .main-line{
//             height: .4px;
//             width: 96%;
//             opacity: 0.5;
//             border: 1px solid #dcdce4;
//         }
//         .choose-category{
//             padding-top: 30px;
//         }
//         .form-group{
//             .padd-top{
//                 padding-top: 30px;
//             }
//             label{
//                 font-family: 'Nunito'!important;
//                 font-style: normal!important;
//                 font-weight: bold!important;
//                 font-size: 16px!important;
//                 line-height: 22px!important;
//                 color: #000000!important;
//             }
//             input{
//                 background: #FAFAFB;
//                 border: 1px solid #EFEFEF;
//                 box-sizing: border-box;
//                 border-radius: 5px;
//                 height: 65px;
//                 &::placeholder{
//                     color: #CDCDCD;
//                     font-family: 'Poppins', sans-serif;
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 16px;
//                     line-height: 22px;
//                 }
//             }
//             textarea{
//                 background: #FAFAFB;
//                 border: 1px solid #EFEFEF;
//                 box-sizing: border-box;
//                 border-radius: 5px;
//                 &::placeholder{
//                     color: #CDCDCD;
//                     font-family: 'Poppins', sans-serif;
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 16px;
//                     line-height: 22px;
//                 }
//             }
//             .main-add-ques{
//                 display: flex;
//                 justify-content: start;
//                 align-items: center;
//                 .main-question{
//                     font-family: 'Poppins', sans-serif;
//                     font-style: normal;
//                     font-weight: bold;
//                     font-size: 16px;
//                     line-height: 22px;
//                     color: #133572;
//                     padding-left: 14px;
//                 }
//             }
//             .videopage{
//                 margin-top: 70px;
//                 .red-b{
//                     outline: none;
//                     box-shadow: none;
//                     background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
//                     border-radius: 10px;
//                     width: 170px;
//                     height: 50px;
//                     border: 1px solid #133572;
//                     font-family: 'Poppins', sans-serif;
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 14px;
//                     line-height: 19px;
//                     color: #ffffff;
//                     margin-right: 20px;
//                     transition: .4s ease-in-out;
//                     &:hover{
//                         background: transparent;
//                         border: 1px solid #3568C1;
//                         color: #3568C1;
//                     }
//                 }
//                 .red-w{
//                     outline: none;
//                     box-shadow: none;
//                     background: #FFFFFF;
//                     border: 1px solid #3568C1;
//                     box-sizing: border-box;
//                     border-radius: 10px;
//                     width: 150px;
//                     height: 50px;
//                     font-family: 'Poppins', sans-serif;
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 14px;
//                     line-height: 19px;
//                     color: #3568C1;
//                     transition: .4s ease-in-out;
//                     &:hover{
//                         background: linear-gradient(180deg, #133572 0%, #3568C1 100%);
//                         color: #ffffff;
//                         border: 1px solid #133572;
//                     }
//                 }
//             }

//         }
//         .form-check{
//             label{
//                 font-family: 'Poppins', sans-serif;
//                 font-style: normal;
//                 font-weight: normal;
//                 font-size: 16px;
//                 line-height: 22px;
//                 color: #030229!important;
//                 line-height: 33px;
//             }
//             input[type=radio] {
//                 width: 20px;
//                 height: 20px;
//                 background: linear-gradient(180deg, #133572 0%, #3568C1 100%)!important;
//                 border: 1.5px solid #133572!important;
//                 margin-left: 0px;
//                 z-index: 1111;
//             }
//         }

//         .rightside-for-group{
//             .dashed-border-new{

//                 border: 1px dashed #C6C6C6;
//                 box-sizing: border-box;
//                 border-radius: 5px;
//                 background-color: transparent;
//                 height: 287px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 p{
//                     font-family: 'Poppins', sans-serif;
//                     font-style: normal;
//                     font-weight: 600;
//                     font-size: 16px;
//                     line-height: 22px;
//                     color: #CDCDCD;
//                     padding-top: 20px;
//                 }
//                 .main-image-div{
//                     text-align: center;
//                 }
//             }
//         }
//     }
// }

.innertable_user_bannar {
  .namemain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    .namepara {
      color: #5f6d7e;
      font-size: 13px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.013px;
    }
  }

  table {
    width: 100%;

    thead {
      padding: 0px;

      th {
        color: #5f6d7e;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.013px;
        cursor: pointer;
        padding: 20px;
        vertical-align: middle;
        border-bottom: 1px solid #f3f7fb;
        white-space: nowrap;
      }
    }

    tbody {
      padding: 0px;

      tr {
        td {
          color: #5f6d7e;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.014px;
          border-bottom: 1px solid #f3f7fb;
          background: var(--White, #fff);
          cursor: pointer;
          padding: 20px;
          vertical-align: middle;

          // width: 33%;
          .eleipiess {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            display: block;
            white-space: pre-wrap;
            width: 100%;
            cursor: pointer;
          }

          .mainimgdiv {
            display: flex;

            align-items: center;
            gap: 16px;

            .inerimgd {
              display: flex;
              width: 91px;
              height: 58px;
              padding: 11px 27px 10px 26px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              border-radius: 5px;
              background: var(--Buttons-Gradient,
                  linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%));
              object-fit: cover;
              object-position: center;

              .tableimgginer {
                width: 100%;
                height: 100%;
                //   border-radius: 30px;
                object-fit: cover;
                object-position: center;
              }
            }

            .tableimgtext {
              color: #2e3646;

              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.014px;
              white-space: nowrap;
            }
          }

          .detailbtn {
            border-radius: 40px;
            background: #f5f9ff;
            display: flex;
            padding: 9px 37px;
            justify-content: center;
            align-items: center;
            color: #3553d5;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.012px;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}

.onlyimggsizecommon {
  width: 91px;
  height: 58px;
  object-position: center;
  object-fit: cover;
}

// .innertable_user {
//   .namemain {
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     gap: 16px;
//     .namepara {
//       color: #5f6d7e;
//       font-size: 13px !important;
//       font-style: normal;
//       font-weight: 500;
//       line-height: 18px;
//       letter-spacing: -0.013px;
//     }
//   }
//   table {
//     width: 100%;
//     thead {
//       padding: 0px;
//       th {
//         color: #5f6d7e;
//         font-size: 13px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: 18px;
//         letter-spacing: -0.013px;
//         padding: 16px 24px;
//         vertical-align: middle;
//         border-bottom: 1px solid #f3f7fb;
//         white-space: nowrap;
//       }
//     }
//     tbody {
//       padding: 0px;
//       tr {
//         td {
//           .eleipiess {
//             overflow: hidden;
//             text-overflow: ellipsis;
//             width: 109px;
//             display: block;
//             white-space: nowrap;
//           }
//           .mainimgdiv {
//             display: flex;

//             align-items: center;
//             gap: 16px;
//             .inerimgd {
//               width: 32px;
//               height: 32px;
//               border-radius: 30px;
//               object-fit: cover;
//               object-position: center;
//               .tableimgginer {
//                 width: 100%;
//                 height: 100%;
//                 border-radius: 30px;
//                 object-fit: cover;
//                 object-position: center;
//               }
//             }
//             .tableimgtext {
//               color: #2e3646;

//               font-size: 14px;
//               font-style: normal;
//               font-weight: 500;
//               line-height: 20px;
//               letter-spacing: -0.014px;
//               white-space: nowrap;
//             }
//           }
//           color: #5f6d7e !important;
//           font-size: 14px;
//           font-style: normal;
//           font-weight: 500;
//           line-height: 20px;
//           letter-spacing: -0.014px;
//           border-bottom: 1px solid #f3f7fb;
//           background: var(--White, #fff);
//           padding: 16px 24px;
//           vertical-align: middle;
//           white-space: nowrap !important;

//           .detailbtn {
//             border-radius: 40px;
//             background: #f5f9ff;
//             display: flex;
//             padding: 9px 37px;
//             justify-content: center;
//             align-items: center;
//             color: #3553d5;
//             font-size: 12px;
//             font-style: normal;
//             font-weight: 500;
//             line-height: 18px;
//             letter-spacing: -0.012px;
//             border: none;
//             outline: none;
//           }
//         }
//       }
//     }
//   }
// }
.addbanner12345 {
  width: 100%;
  min-height: 100%;

  .inner-submit-lower-div {
    padding-top: 30px;
    padding-bottom: 30px;

    .main-line {
      height: 0.4px;
      width: 96%;
      opacity: 0.5;
      border: 1px solid #dcdce4;
    }

    .choose-category {
      padding-top: 30px;
    }

    .form-group {
      cursor: pointer !important;

      .padd-top {
        padding-top: 30px;
      }

      label {
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 16px !important;

        color: #000000 !important;
        cursor: pointer;
        font-weight: 500 !important;
        line-height: normal !important;
      }

      .nnnnhhew {
        color: #5f6d7e;
        font-family: Poppins;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
      }

      input {
        // background: #fafafb;
        // border: 1px solid #efefef;
        border: 1px solid #EFEFEF;

        background: #FAFAFB;
        box-shadow: none;
        border-radius: 5px;
        height: 65px;
        box-shadow: none;
        outline: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        cursor: pointer !important;
        color: #495057 ;

        &::placeholder {
          color: #cdcdcd;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }

      textarea {
        background: #fafafb;
        border: 1px solid #efefef;
        box-shadow: none;
        border-radius: 5px;
        padding: 20px;
        color: #495057;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer !important;

        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .main-add-ques {
        display: flex;
        justify-content: start;
        align-items: center;

        .main-question {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #133572;
          padding-left: 14px;
        }
      }

      .videopage {
        margin-top: 70px;

        .red-b {
          outline: none;
          box-shadow: none;
          border-radius: 60px;
          background: var(--Buttons-Gradient,
              linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%));
          width: 170px;
          height: 50px;
          border: 1px solid #133572;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin-right: 20px;
          transition: 0.4s ease-in-out;

          &:hover {
            background: transparent;
            border: 1px solid #3568c1;
            color: #3568c1;
          }
        }

        // .red-w {
        //   outline: none;
        //   box-shadow: none;
        //   border-radius: 60px;
        //   border: 1px solid #3568c1;
        //   background: var(--White, #fff);
        //   box-sizing: border-box;
        //   //   border-radius: 10px;
        //   width: 150px;
        //   height: 50px;
        //   font-family: "Poppins", sans-serif;
        //   font-style: normal;
        //   font-weight: 600;
        //   font-size: 14px;
        //   line-height: 19px;
        //   background: var(
        //     --Buttons-Gradient,
        //     linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
        //   );

        //   transition: 0.4s ease-in-out;
        //   &:hover {
        //     background: linear-gradient(180deg, #133572 0%, #3568c1 100%);
        //     color: #ffffff;
        //     border: 1px solid #133572;
        //   }
        // }
      }
    }

    .form-check {
      display: flex;
      align-items: center;

      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #030229 !important;
        line-height: 33px;
      }

      input[type="radio"] {
        width: 20px;
        height: 20px;
        background: linear-gradient(180deg,
            #133572 0%,
            #3568c1 100%) !important;
        border: 1.5px solid #133572 !important;
        // margin-left: 0px;
        z-index: 1111;
        padding: 20px;
      }
    }

    .rightside-for-group {
      .dashed-border-new {
        border: 1px dashed #c6c6c6;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: transparent;
        height: 287px;
        max-width: 386px;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #cdcdcd;
          padding-top: 20px;
        }

        .main-image-div {
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .drtuop {
      color: #cdcdcd;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      .commonfffr {
        background: var(--Buttons-Gradient,
            linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }
}

.textareasizeonly {
  height: 86px !important;
  cursor: pointer;

}

@media (max-width: 600px) {
  .addbanner12345 {
    .inner-submit-lower-div {
      .form-group {
        textarea {
          &::placeholder {
            font-size: 14px;
          }
        }

        input {
          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }

    .videopage {
      margin-top: 20px !important;

      .red-b {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .red-w {
        width: 100% !important;
      }
    }
  }
}

.imgages-no {
  width: 92px !important;
}

.buttons-remove {
  border: none;
  background-color: transparent;
}

.button-listing {
  background: #fafafb;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 5px;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.zscscsac {
  width: 100%;
}

@media (max-width: 1199px) {
  .innertable_user_bannar table tbody tr td {
    white-space: nowrap;
  }
}

.forborder{
  border: none !important;
}