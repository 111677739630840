.boltmanagement {
    background: #FFFFFF;
    border-radius: 10px;

    .table-responsive {
        .table {
            thead {
                border-bottom: none;
                cursor: pointer;
                padding: 20px;

                th {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    letter-spacing: 0.1px;
                    color: #030229;
                    opacity: 0.7;
                    padding-bottom: 20px;
                    padding-left: 0px;
                }
            }

            tbody {
                td {
                    padding: 30px 0px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #030229;
                    cursor: pointer;
                    padding: 20px;

                    .parara {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px !important;
                        line-height: 150%;
                        color: #030229;
                    }
                }
            }
        }
    }
}

.boltcategories {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
    height: 88vh;
    position: relative;

    input {
        background: #FAFAFB;
        border: 1px solid #EFEFEF;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        width: 100%;

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #c6c6c6;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 15px;
    }

    .last {
        display: flex;
        position: absolute;
        left: 30px;
        bottom: 30px;

        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 13px 45px;
            background: #FFFFFF;
            border: 1px solid #3568C1;
            border-radius: 60px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #3553D5;
            margin-right: 20px;

            &:hover {
                transition: 1s;
                background: linear-gradient(89.93deg, #3553D5 50.15%, #0C194E 217.12%);
                color: #FFFFFF;
             
            }
        }
    }
}

.boltcategoriesModal {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0px 30px 30px 30px;
    height: unset !important;
    position: relative;
}
@media (max-width:601px){
    .button-modal-daily55{
        display: flex;
        justify-content: center;
    }
    .boltcategoriesModal {
        padding: 0px 10px 10px 10px;
    }
}