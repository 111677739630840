// .view-tickets {
//   z-index: 999999;
// }

.raffles {
  background: #ffffff;
  border-radius: 10px;
  padding-left: 0px;
  padding-right: 0px;

  .ended-content {
    .sub-img {
      width: 62px;
      height: 57px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .blue-btn {
    background: linear-gradient(89.93deg, #3553d5 50.15%, #0c194e 217.12%);
    border-radius: 60px;
    padding: 12px 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border: none;
  }
}

.rafleoutrimgdiv {
  width: 58px;
  height: 58px;
  border-radius: 5px;
  overflow: hidden;

  .inerimgrafel {
    height: 58px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    object-position: center;
    object-fit: fill;
  }
}

.bottombtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 70px;
}

.bg-color-raffle {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
}

.addraffles {
  padding: 20px;

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    display: block;
  }

  input {
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    max-width: 700px;
    width: 100%;

    &::placeholder {
      color: #cdcdcd;
    }
  }

  .dropdown-toggle {
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    max-width: 700px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:active {
      background-color: #fafafb !important;
      border: 1px solid #efefef !important;
      color: #000 !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    max-width: 700px;
    width: 100%;
  }

  .upload-img {
    margin-bottom: 60px;

    .upload {
      background: #ffffff;
      border: 1px dashed #c6c6c6;
      border-radius: 5px;
      max-width: 386px;
      width: 100%;
      height: 287px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .upload-imgggg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin: 0px;
        padding: 0px;
      }

      label {
        cursor: pointer;
      }

      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #cdcdcd;

        label {
          background: linear-gradient(89.93deg,
              #3553d5 50.15%,
              #0c194e 217.12%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin: 0;
          display: inline;
          cursor: pointer;
        }
      }
    }
  }

  .option-field {
    margin-bottom: 30px;
  }

  .bottom-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 70px;

    button {
      background: #ffffff;
      border: 1px solid #3568c1;
      border-radius: 60px;
      padding: 13px 45px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #3553d5;

      &:hover {
        background: linear-gradient(89.93deg, #3553d5 50.15%, #0c194e 217.12%);
        color: #ffffff;
      }
    }
  }

  textarea {
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    max-width: 700px;
    width: 100%;
    height: 160px;

    &::placeholder {
      color: #cdcdcd;
    }
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show>.btn-secondary.dropdown-toggle {
    background-color: #fafafb !important;
    border: 1px solid #efefef !important;
    color: #000 !important;
  }

  .twice-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 700px;
    width: 100%;

    .option-field {
      width: 48%;
    }
  }
}

.topCard {
  padding: 22px;
  border: 1px solid #a5afbf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  margin-bottom: 25px;

  .pH {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #a5afbf;
    }

    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: #343434;
    }
  }
}

.sidebar-raffle {
  .main-card {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #343434;
      margin-bottom: 32px;
    }

    .ticket {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 33px;
      border-bottom: 1px solid #f1f1f1;
      padding: 16px 0px;

      &:last-child {
        margin-bottom: 0;
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          color: #343434;
        }

        .profile {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .inner-left {
            margin-left: 16px;

            .profile-img {
              width: 44px;
              height: 44px;
              overflow: hidden;
              border-radius: 50%;
              background-color: #3553d5;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }

          .inner-right {
            margin-left: 10px;

            h6 {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 130%;
              color: #343434;
            }

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              color: #a5afbf;
            }
          }
        }
      }

      .right {
        .blue-btn {
          background: linear-gradient(89.93deg,
              #3553d5 50.15%,
              #0c194e 217.12%);
          border-radius: 60px;
          padding: 6px 13px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
}

.view-tickets {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);

  .modal-content {
    background: #ffffff;
    border-radius: 10px;
  }

  .modal-dialog {
    max-width: 574px;
  }

  .modal-header {
    border: none;
    padding: 40px;
    padding-bottom: 0;
    color: #222;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    /* 93.333% */
    .modal-title {
      h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 28px;
        color: #222222;
      }
    }
  }

  .modalhndinggggff {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 28px;
    color: #222222 !important;
  }

  .modal-body {
    padding: 40px;

    .countsss {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px 5px;

      .count-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 1px solid #f1f4fc;
        border-radius: 50px;
        flex: 0 0 77px;
        padding: 5px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #3456d0;
      }
    }

    .count-active {
      background-color: #2cc84a !important;
      color: #ffffff !important;
    }
  }
}

.btncomxxxxxon {
  margin-top: 25px;
  border-radius: 150px;
  border: 1px solid #3568c1;
  background: var(--White, #fff);
  padding: 15px;
  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--Buttons-Gradient,
      linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.addraffles .upload-img .upload {
  position: relative;
}

.addraffles label {
  z-index: 99;
}

.addraffles .upload-img .upload h6 {
  position: relative;
  z-index: 99;
}