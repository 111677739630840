.productss {
  // background: #ffffff;
  // border-radius: 10px;
  // padding: 0px 20px;

  .productstable {
    border-radius: 10px;
    padding: 0px 20px;
    border: 1px solid #f3f7fb;
    background: #ffffff;
    table {
      width: 100%;

      thead {
        th {
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 138.462% */
          letter-spacing: -0.013px;
          padding-bottom: 18px;

          &:last-child {
            display: flex;
            justify-content: center;
          }

          .arrowdown {
            margin-left: 10px;
            margin-bottom: 2px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 20px 0px;
            color: #5f6d7e;

            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.014px;
            .productimg {
              width: 91px;
              height: 58px;
              background: linear-gradient(180deg, #ffb800 0%, #ff8a00 101.75%),
                linear-gradient(180deg, #3456d0 0%, #2542a6 100%);
              border-radius: 5px !important;
overflow: hidden;
              .innerimg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 5px !important;
              }
            }

            .darktext {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }

            .bluebtn {
              background: linear-gradient(
                89.93deg,
                #3553d5 50.15%,
                #0c194e 217.12%
              );
              border-radius: 60px;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              color: #ffffff;
              padding: 12px 40px;
              border: none;
            }

            &:last-child {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
.detailbtn {
  border-radius: 40px;
  background: #f5f9ff;
  display: flex;
  padding: 9px 37px;
  justify-content: center;
  align-items: center;
  color: #3553d5;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.012px;
  border: none;
  outline: none;
}

.dflexfortableonly {
  display: flex;
  height: 64px;
  padding: 12px 24px;
  align-items: baseline;
  gap: 16px;
  /* align-self: stretch; */
  border-bottom: 1px solid #f3f7fb;
  background: var(--White, #fff);
}
.newsizionly {
  width: 276px;
  height: 276px;
}

.addproductform {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  min-height: 100vh;
  width: 100%;

  .head {
    color: #000;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding-bottom: 15px;
  }

  .droped {
    .dropbtn {
        border-radius: 8px;
        border: 1.5px solid #e1ecff;
        background: var(--White, #fff);
  
        border-radius: 8px;
        padding: 13px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 161px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        &:hover {
            border: 1.5px solid #e1ecff;
        }
      }

    .dropmain {
      // width: 100%;
      border-radius: 10px;
      padding-top: 0px !important;

      .dropdown-item.active,
      .dropdown-item:active {
        background-color: unset;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: #e1ecff;
        width: 100%;
      }
    }
  }

  input {
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 30px;

    &::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #cdcdcd;
    }
  }

  textarea {
    height: 111px;
    padding: 20px;
    background: #fafafb;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 30px;

    &::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #cdcdcd;
    }
  }

  .upload-parent {
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      cursor: pointer;

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #cdcdcd;

        span {
          color: blue;
          text-decoration: underline;
        }
      }
    }

    .upload {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 386px;
      height: 287px;
      background: #ffffff;
      border: 1px dashed #c6c6c6;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 60px;
    }

    .after-upload {
      background: #0f0f0f;
      border: 1px solid #151515;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .parent-main {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .left {
          width: 72px;
          height: 54px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .right {
          margin-left: 20px;

          h6 {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 12px;
            line-height: 170%;
            color: #ffffff;
          }

          p {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            line-height: 170%;
            color: #797979;
          }
        }
      }

      .loading {
        opacity: 0.4;
      }

      .parent-right {
        i {
          -webkit-animation: spin 4s linear infinite;
          -moz-animation: spin 4s linear infinite;
          animation: spin 4s linear infinite;
        }

        @-moz-keyframes spin {
          100% {
            -moz-transform: rotate(360deg);
          }
        }

        @-webkit-keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .addproductform {
    padding: 0px;
  }

  .addproductform .upload-parent .upload {
    width: 100%;
  }

  .products {
    padding: 10px;

    .productstable {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      table {
        width: 650px;
      }
    }
  }
}

.jodit-status-bar {
  display: none !important;
}

.jodit-react-container {
  margin-bottom: 30px;
}

.hhhhhhhhh{
  max-width: 400px;
  overflow: hidden;
  text-overflow:ellipsis;

}
