.products {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 27px 25px;

    .productstable {
        table {
            width: 100%;

            thead {
                th {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #030229;
                    opacity: 0.7;
                    padding-bottom: 18px;

                    &:last-child {
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 10px;
                    }

                    .arrowdown {
                        margin-left: 10px;
                        margin-bottom: 2px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 20px 0px;

                        .productimg {
                            width: 91px;
                            height: 58px;
                            background: linear-gradient(180deg, #FFB800 0%, #FF8A00 101.75%), linear-gradient(180deg, #3456D0 0%, #2542A6 100%);
                            border-radius: 5px;

                            .innerimg {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }

                        .darktext {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #000000;
                        }

                        .bluebtn {
                            background: linear-gradient(89.93deg, #3553D5 50.15%, #0C194E 217.12%);
                            border-radius: 60px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            text-align: center;
                            color: #FFFFFF;
                            padding: 12px 40px;
                            border: none;
                        }

                        &:last-child {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
}

.forellipise{
    max-width: 400px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}