.button-main-daily {
  outline: none;
  box-shadow: none;

  border-radius: 10px;
  width: 170px;
  height: 50px;
  border: 1px solid #e72d1d !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff !important;
  margin-right: 20px;
  transition: 0.4s ease-in-out;
  border-radius: 60px;
  background: #f04914 !important;

  &:hover {
    background: #f04914 !important;
    border: 1px solid #c14535 !important;
    color: #f04914 !important;
  }
}

.fontweightonly {

  font-weight: 400 !important;
}

.tablehndsameblurnew {
  font-size: 15px !important;
}

.imgagesno_roundimgonly {
  width: 58px;
  height: 58px;
  border-radius: 50%;
}

.button-main-dailys {
  outline: none;
  box-shadow: none;
  background: #ffffff !important;
  border: 1px solid #3568c1 !important;
  box-sizing: border-box;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3568c1;
  transition: 0.4s ease-in-out;

  &:hover {
    background: linear-gradient(180deg, #1d7be7 0%, #1d8ce7 100%) !important;
    color: #ffffff !important;
    border: 1px solid #1d8ce7 !important;
  }
}


.selectedIm-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 287px;
  margin-bottom: 0px;

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}


.copy_check:before {
  content: "\f00c";
  color: #3553d5;
  font-size: 17px;
}

.token-img {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}