.button-main-dailyred {
  outline: none;
  box-shadow: none;
  background: linear-gradient(180deg, #e72d1d 0%, #e71d36 100%) !important;
  border-radius: 60px;
  width: 170px;
  height: 50px;
  border: 1px solid #e72d1d !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff !important;
  margin-right: 20px;
  transition: 0.4s ease-in-out;

  &:hover {
    background: transparent !important;
    border: 1px solid #c14535 !important;
    color: #c14535 !important;
  }
}

// .njdhbcdhbc .form-check input[type=checkbox]{
// visibility: visible!important;
// opacity: 1!important;
// }
.form-check input[type="checkbox"],
.form-check-radio input[type="radio"] {
  visibility: visible !important;
  opacity: 1 !important;
}
.button-modal-daily {
  display: flex;
  /* width: 351px; */
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
}
.button-main-dailys {
  outline: none;
  box-shadow: none;
  background: #ffffff !important;
  border: 1px solid #3568c1 !important;
  box-sizing: border-box;
  border-radius: 60px;
  width: 150px;
  height: 50px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3568c1;
  transition: 0.4s ease-in-out;

  &:hover {
    background: linear-gradient(180deg, #1d7be7 0%, #1d8ce7 100%) !important;
    color: #ffffff !important;
    border: 1px solid #1d8ce7 !important;
  }
}

.inner-submit-lower-div {
  .adminheading {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0.5rem;
  }

  .droped {
    width: 100%;

    .dropbtn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fafafb;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #d1d1d1;
      margin-bottom: 20px;
    }

    .dropmain {
      width: 100%;
      background: #fafafb;
      border: 1px solid #efefef;
      max-height: 300px;
      min-height: 100px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .drop {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #030229;
        line-height: 33px;

        &:hover {
          color: #16181b;
          text-decoration: none;
          background-color: unset !important;
        }

        &:focus {
          background-color: unset !important;
        }

        &:active {
          background-color: unset !important;
        }
      }
    }
  }
}

.addrole {
  // box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;

  .form-group {
    .adminheading {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0.5rem;
    }
  }

  .outermain {
    .form-group {
      .adminheading {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 0.5rem;
      }

      .roleinput {
        &:focus {
          box-shadow: none !important;
          border-color: #efefef;
        }
      }

      .adminheading {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 0.5rem;
      }
    }
  }

  .form-check-input {
    margin-top: 0.39rem;
  }

  .form-check .form-check-label {
    padding-left: 0px;
  }
}
.mainshead {
  display: flex;
  align-items: flex-start;

  gap: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.warrrrw {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.reddettll {
  color: #f04914;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
}
.ccdaadasdasdsa {
  color: #030229;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.14px;
}
.button-modal-dailyssss {
  display: flex;
  /* width: 351px; */
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.tdclasss {
  color: #5f6d7e !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  // line-height: 120px !important; /* 142.857% */
  letter-spacing: -0.014px;
  // width: auto !important;
}
.edittttbut {
  color: #3553d5;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  letter-spacing: -0.012px;
  padding: 9px 37px;
  border-radius: 40px;
  border: unset;
  background: #f5f9ff;
}
.dfgsgdfsgdsfsggsd {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0px auto;
}

.dfgsgdfsgdsfsggsdss {
  display: flex;
  justify-content: start;
  text-align: start;
  align-items: center;
  padding-left: 37px !important;
}
.perdiv {
  color: #000;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100% !important; /* 14px */
}
.radiousssd {
  border-radius: 60px !important;
}
.warrrr {
  display: flex;
  padding: 17px 20px;
  // border-radius: 5px;
  // border: 1px solid #efefef;
  margin-top: 15px;
  // background: var(--White, #fff);
  border-radius: 5px;
border: 1px solid #EFEFEF;

background: #FAFAFB;
  justify-content: space-between;
  align-items: center;
  color: #CDCDCD;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  &:hover{
    color: #CDCDCD;
  }
}
.formcheck_newwwwwss {
  width: 22px;
  height: 22px;
  fill: var(
    --Buttons-Gradient,
    linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
  );
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdown-itemw {
  border-bottom: 1px solid #f3f7fb;
  display: flex;
  width: 682px;
  padding: 15px 14px;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  &:hover{
    background: #EDF4FF !important;
    color: #000 !important;
  }
}
.warrrrw {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.butttttt {
  color: var(--White, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  padding: 17px 59px;
  border-radius: 60px;
  background: var(
    --Buttons-Gradient,
    linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
  );
  width: 100%;
  border: unset;
}
.videopage {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

@media (max-width: 600px) {
  .mainshead {
    display: flex;
    align-items: flex-start;
    /* grid-gap: 20px; */
    gap: 9px;
    padding-bottom: 20px;
    padding-top: 20px;
    white-space: nowrap;
    flex-direction: column;
  }
}




  .toggle {
    position: relative;
    box-sizing: border-box;
  }
  .toggle input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .toggle label {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .toggle label:before {
    content: '';
    width: 75px;
    height: 42px;
    background: #ccc;
    position: relative;
    display: inline-block;
    border-radius: 46px;
    box-sizing: border-box;
    transition: 0.2s ease-in;
  }
  .toggle label:after {
    content: '';
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    left: 2px;
    top: 2px;
    z-index: 2;
    background: #fff;
    box-sizing: border-box;
    transition: 0.2s ease-in;
  }
  .toggle input[type="checkbox"]:checked + label:before {
    background: #4BD865;
  }
  .toggle input[type="checkbox"]:checked + label:after {
    left: 35px;
  }

  .hhhhhdkhlkhlkh{
    border: none !important;
  }
.gggggggggg{
  border-radius: 5px;
border: 1px solid #EFEFEF !important;

background: #FAFAFB !important;
padding: 20px !important;
box-shadow: none;
outline: none;
&:focus{
  box-shadow: none !important;
}
}
label.text-centerr{
  cursor: pointer;
}
a.namediv.dropdown-item{
  padding: 10px;
}
.tttttttt{
  border-bottom: none !important;
}

