.main-reffrel {
    background-color: #fff;
    .inner-logo {
        img {
            width: 150px;
        }
    }
    .cmn-tile-style {
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        margin-top: 30px;
        h2 {
            font-size: 30px;
        }

        h6 {
            font-weight: normal;
            color: #636363;
            text-align: center;
           
        }

        label {
            font-family: 'Poppins', sans-serif;
            font-style: normal!important;
            font-weight: bold!important;
            font-size: 16px!important;
            line-height: 22px!important;
            color: #000000!important;
            padding-top: 26px;
        }
        input {
            background: #FAFAFB;
            border: 1px solid #EFEFEF;
            box-sizing: border-box;
            border-radius: 5px;
            height: 65px;
            opacity: 1;
    visibility: visible;
    margin-left: 0;
            &::placeholder {
                color: #CDCDCD;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .inputErrors{
        color: red;
    }
}


.MuiBackdrop-root.loader{
    z-index: 20000!important;
}

.MuiCircularProgress-root{
    width: 50px!important;
    height: 50px!important;
    color: white;
} 