.settings {
  width: 100%;
  min-height: 100vh;

  .main-div-legion {
    h3 {
      color: #000;
      font-family: Poppins;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
      padding-top: 100px;
      padding-bottom: 30px;
    }

    .legion-main-pro {
      display: flex;
      margin-bottom: 30px;

      .images-legion {
        padding-right: 25px;
      }
    }
  }
}
.videopage {
  margin-top: 70px;
}
.settings .tabs-head .nav-div {
  width: 94%;
  margin: 0 auto;
  border-bottom: 2px solid #e9e9ee;
}

.settings .tabs-head .nav-divs {
  width: 94%;
  margin: 0 auto;
}

.settings .tabs-head .nav-tabs {
  border: none;
}

.settings .tabs-head .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000 !important;
  border: none !important;
  background-color: transparent !important;
  border-bottom: 4px solid #000000 !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  margin-right: 26px;
  font-size: 18px !important;
  line-height: 22px;
}

.settings .tabs-head .nav-tabs a {
  color: #585e67;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;
  border: none;
}

.settings .tabs-head .nav-tabs a:hover {
  color: #000000 !important;
}

.settings .tabs-head .nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: unset;
  text-align: unset;
}

.settings .tabs-head {
  padding-top: 20px;
}

.settings {
  .rightside-for-group {
    label {
      color: #000;
cursor: pointer;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .dashed-border-new {
      border: 1px dashed #c6c6c6;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: transparent;
      height: 287px;
      max-width: 386px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #cdcdcd;
        padding-top: 20px;
      }

      .main-image-div {
        text-align: center;
      }
    }
  }
}

.settings .main-second-card h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 27px;
  color: #000000;
  padding-top: 50px;
}

.main-second-cards {
  .form-group {
    .padd-top {
      padding-top: 30px;
    }

    label {
      color: #000;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 30px;
    }

    .main-label-m {
      font-family: "Poppins", sans-serif;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 22px !important;
      color: #2753a0 !important;
      padding-top: 0px !important;
      margin: 0px !important;
      cursor: pointer !important;
    }

    input {
      background: #fafafb;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 5px;
      height: 65px;

      &::placeholder {
        color: #cdcdcd;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }

    textarea {
      background: #fafafb;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 5px;

      &::placeholder {
        color: #cdcdcd;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
.unboldtexttable {
  color: var(--Text, #030229) !important;
  font-family: Poppins;
  font-size: 14px !important;

  font-weight: 400 !important;
  line-height: 54px;
}
.settings .main-second-card {
  .form-group {
    .videopage {
      margin-top: 70px;

      .red-b {
        outline: none;
        box-shadow: none;
        background: var(
          --Buttons-Gradient,
          linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
        );
        border-radius: 10px;
        width: 170px;
        height: 50px;
        border: 1px solid #133572;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        margin-right: 20px;
        transition: 0.4s ease-in-out;

        &:hover {
          background: transparent;
          border: 1px solid #3568c1;
          color: #3568c1;
        }
      }

      .red-w {
        outline: none;
        box-shadow: none;
        background: #ffffff;
        border: 1px solid #3568c1;
        box-sizing: border-box;
        border-radius: 10px;
        width: 150px;
        height: 50px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #3568c1;
        transition: 0.4s ease-in-out;

        &:hover {
          background: linear-gradient(180deg, #133572 0%, #3568c1 100%);
          color: #ffffff;
          border: 1px solid #133572;
        }
      }
    }

    .padd-top {
      padding-top: 30px;
    }

    label {
      font-family: "Poppins", sans-serif;
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 22px !important;
      color: #000000 !important;
      padding-top: 30px;
    }

    input {
      background: #fafafb;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 5px;
      height: 65px;

      &::placeholder {
        color: #cdcdcd;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }

    textarea {
      background: #fafafb;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 5px;

      &::placeholder {
        color: #cdcdcd;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.tasks {
  background: #ffffff;
  border-radius: 10px;
  .whitespace {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-responsive {
    .table {
      thead {
        tr {
          th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: 0.1px;
            color: #030229;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .imgages-no {
    width: 65px !important;
    border-radius: 50%;
  }

  .main-image {
    width: 40px;
    height: 40px;
    border-radius: 4px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 4px;
    }
  }

  .rrrsrsr {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rrrsrsrr {
    max-width: 60px;
  }
}

.addsocial {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  .main-second-cards {
    .form-group {
      .padd-top {
        padding-top: 30px;
      }

      label {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 30px;
      }

      .main-label-m {
        font-family: "Poppins", sans-serif;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 22px !important;
        color: #2753a0 !important;
        padding-top: 0px !important;
        margin: 0px !important;
        cursor: pointer !important;
      }

      input {
        border-radius: 5px;
        border: 1px solid #efefef;
        background: #fafafb;
        box-sizing: border-box;
        padding: 20px;
        height: 65px;
        cursor: pointer !important;
        box-shadow: none;
        color: var(--Text, #9A9AA9);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &::placeholder {
          color: #9A9AA9;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }

      textarea {
        background: #fafafb;
        border: 1px solid #efefef;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer !important;
        box-shadow: none;
        color: var(--Text, #9A9AA9);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &::placeholder {
          color: #9A9AA9;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .tasks {
    .table-responsive.overflow-responce {
      .table {
        width: 1000px;
        overflow-x: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .settings {
    .main-div-legion {
      .legion-main-pro {
        .images-legion {
          padding-right: 4px;
          margin-bottom: 20px;
        }

        .img {
          max-width: unset;
        }

        flex-wrap: wrap !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 33px;
      }
    }

    .videopage {
      margin-top: 20px !important;

      .red-b {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .red-w {
        width: 100% !important;
      }
    }
  }
}

.modal-content.daily-profile-modal-inner {
  border-radius: 20px;
  border: 1px solid #F3F7FB;
  background: var(--White, #FFF);
  backdrop-filter: blur(2px);
}
.dffafasfasfasfasfas {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 76px;
  margin-left: 29px;
}
.withhonlyunset {
  width: 100% !important;
  color: var(--Text, #030229) !important;

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.red-b {
  outline: none;
  box-shadow: none;
  border-radius: 150px;
  background: var(
    --Buttons-Gradient,
    linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
  );
  width: 170px;
  height: 50px;
  border: 1px solid #3553d5;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-right: 20px;
  transition: 0.4s ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #3568c1;
    color: #3568c1;
  }
}

.red-w {
  outline: none;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #3553d5;
  box-sizing: border-box;
  border-radius: 150px;
  width: 150px;
  height: 50px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3568c1;
  transition: 0.4s ease-in-out;

  &:hover {
    background: var(
      --Buttons-Gradient,
      linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
    );
    color: #ffffff;
    border: 1px solid #3553d5;
  }
}

.main-inout-nh {
  width: 100%;
}

.main-divs-image {
  border: 2px dashed rgb(228, 227, 227);
  width: 200px;
  border-radius: 4px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
  }
}

.main-label-m {
  font-family: "Poppins", sans-serif;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #2753a0 !important;
}
.cPointer {
  cursor: pointer !important;
}
.lolgo {
  width: 80px;
  height: 56px;
}
