.edit-page-profile {
 
  .detail-cardss {
    padding-top: 40px;
    text-align: center;
    img {
      max-width: 155px !important;
      border-radius: 118px;
      width: 100%;
      // height: 149px;
    }
    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #cdcdcd;
      padding-top: 5px;
      padding-bottom: 25px;
      text-align: center;
    }
    h3 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      padding-top: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // width: 50%;
      margin: 0 auto;
      text-align: center;
    }
    .change-password {
      border-radius: 10px;
      border: 1.5px solid var(--Buttons-Gradient, #3553d5);
      background: var(--White, #fff);
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      width: 164px;
      height: 44px;
      background: var(
        --Buttons-Gradient,
        linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &:hover {
        background-color: #133572;
        color: #ffffff;
      }
    }
  }
  .edit-cards {
    .upper-edit {
      h5 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: #030229;
      }
    }
    .form-group {
      .videopage {
        margin-top: 50px;
        float: right;
        margin-bottom: 60px;
        .red-b {
          outline: none;
          box-shadow: none;
          border-radius: 60px;
          background: var(
            --Buttons-Gradient,
            linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
          );
          width: 170px;
          height: 50px;
          border: 1px solid #133572;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          margin-right: 20px;
          transition: 0.4s ease-in-out;
          &:hover {
            background: transparent;
            border: 1px solid #3568c1;
            color: #3568c1;
          }
        }
        .red-w {
          outline: none;
          box-shadow: none;

          border: 1px solid #3568c1;
          border-radius: 60px;
          border: 1px solid #3568c1;
          background: var(--White, #fff);
          width: 150px;
          height: 50px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #3568c1;
          transition: 0.4s ease-in-out;
          &:hover {
            background: linear-gradient(180deg, #133572 0%, #3568c1 100%);
            color: #ffffff;
            border: 1px solid #133572;
          }
        }
      }
      .padd-top {
        padding-top: 30px;
      }
      label {
        font-family: "Nunito" !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 16px !important;
        line-height: 22px !important;
        color: #000000 !important;
        padding-top: 30px;
      }
      input {
        background: #fafafb;
        border: 1px solid #efefef;
        // box-sizing: border-box;
        border-radius: 5px;
        height: 65px;
        box-shadow: none;
        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
      textarea {
        background: #fafafb;
        border: 1px solid #efefef;
        box-sizing: border-box;
        border-radius: 5px;
        &::placeholder {
          color: #cdcdcd;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
  .main-modal-one {
    .daily-profile-modal {
      .daily-profile-modal-inner {
        border-radius: 10px;
        .modal-body-main {
          padding: 50px 40px !important;
          .main-outter {
            .main-cardssss {
              .form-group {
                .padd-top {
                }
                label {
                  font-family: "Poppins", sans-serif;
                  font-style: normal !important;
                  //   font-weight: bold !important;
                  font-size: 18px !important;
                  line-height: 22px;
                  color: #000000 !important;
                  padding-top: 15px;

                  color: var(--Text-Black, #222);
                  font-feature-settings: "clig" off, "liga" off;

                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600 !important;
                  line-height: normal !important;
                  letter-spacing: 0.1px;
                }
                input {
                  border-radius: 5px;
                  border: 1px solid #efefef;
                  background: #fafafb;

                  height: 55px;

                  // padding: 16.773px 277px 14.227px 20px;
                  align-items: center;
                  color: #cdcdcd;
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  box-shadow: none;
                  outline: none;
                  &::placeholder {
                    color: #cdcdcd;
                    font-family: "Poppins", sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                  }
                }
                textarea {
                  background: #fafafb;
                  border: 1px solid #efefef;
                  box-sizing: border-box;
                  border-radius: 5px;
                  &::placeholder {
                    color: #cdcdcd;
                    font-family: "Poppins", sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                  }
                }
              }
        
              .button-modal-daily {
                display: flex;
                justify-content: space-between;
                .button-main-daily {
                  margin-top: 30px;
                  outline: none;
                  box-shadow: none;
                  border-radius: 60px;
                  background: var(
                    --Buttons-Gradient,
                    linear-gradient(90deg, #3553d5 50.15%, #0c194e 217.12%)
                  ) !important;
                  width: 100%;
                  height: 50px;
                  border: none !important;
                  font-family: "Poppins", sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  margin-right: 10px;
                  margin-left: 10px;
                  color: #ffffff;
                  transition: 0.4s ease-in-out;
                  &:hover {
                    background: linear-gradient(
                      180deg,
                      #3568c1 0%,
                      #3568c1 100%
                    ) !important;
                    color: #ffffff !important;
                  }
                }
                .button-main-dailys {
                  margin-top: 30px;
                  outline: none;
                  box-shadow: none;
                  border-radius: 60px;
                  border: 1px solid #3568c1;
                  background: var(--White, #fff);
                  width: 100%;
                  height: 50px;

                  font-family: "Poppins", sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  margin-right: 10px;
                  margin-left: 10px;
                  font-size: 14px;
                  line-height: 19px;
                  color: #3568c1;
                  transition: 0.4s ease-in-out;
                  &:hover {
                    background: linear-gradient(
                      180deg,
                      #3568c1 0%,
                      #3568c1 100%
                    );
                    border: 1px solid #3568c1;
                    color: #ffffff;
                  }
                }
              }
              p {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px !important;
                line-height: 19px;
                color: #818094;
                padding-bottom: 6px;
              }
              h4 {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                color: #030229;
              }
              .flux-b {
                padding-bottom: 30px;
                h3 {
                  font-family: "Poppins", sans-serif;
                  font-style: normal;
                  font-weight: bold !important;
                  font-size: 30px !important;
                  line-height: 28px;
                  color: #222222;
                }
              }
              .flux-r {
                padding-left: 39px;
              }
            }
          }
        }
      }
    }
  }
}
.imgedit {
  width: 155px;
  height: 149px;

  object-fit: cover;
  object-position: center;

  border-radius: 100px;
  border: 4px solid var(--Buttons-Gradient, #3553d5);
  background: #ffc7d4;
  display: flex;

  padding-top: 3px;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  cursor: pointer;
  img.ineredit {
    width: 100%;
    cursor: pointer;
  }
}
.copycamraimgonly_main {
  position: relative;
}
.copycamraimgonly {
  position: absolute;
  width: 38px !important;
  height: 38px !important;
  top: 0px;
  right: 0px;
}
@media (max-width: 600px) {
  .edit-page-profile
    .main-modal-one
    .daily-profile-modal
    .daily-profile-modal-inner
    .modal-body-main {
    padding: 30px 14px !important;
    .main-cardssss .button-modal-daily .button-main-dailys {
      margin-top: 10px !important;
    }
    .main-cardssss .button-modal-daily .button-main-daily {
      margin-top: 10px !important;
    }
  }
  .leaderdetails.edit-page-profile {
    .modal-body-main {
    }
    .edit-cards {
      .upper-edit {
        h5 {
          padding-top: 30px;
          text-align: center;
        }
      }
    }
    .videopage {
      margin-top: 20px !important;
      float: unset !important;
      .red-b {
        width: 100% !important;
        margin-bottom: 20px;
      }
      .red-w {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .edit-page-profile
    .main-modal-one
    .daily-profile-modal
    .daily-profile-modal-inner
    .modal-body-main {
    padding: 30px 3px !important;
    .flux-b {
      padding-bottom: 18px !important;
    }
  }
  .leaderdetails.edit-page-profile {
  }
  .edit-page-profile
    .main-modal-one
    .daily-profile-modal
    .daily-profile-modal-inner
    .modal-body-main
    .main-outter
    .main-cardssss
    .flux-b
    h3 {
    font-size: 24px !important;
  }
}

.inputErrors {
  color: red !important;
}
.pawrddchange {

  margin-top: 23px;
  text-align: center;
}
.pawordchnaged {
  border-radius: 10px;
  border: 1.5px solid var(--Buttons-Gradient, #3553D5);
  background: var(--White, #FFF);
  display: inline-flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 164px;
  background: var(--Buttons-Gradient, linear-gradient(90deg, #3553D5 50.15%, #0C194E 217.12%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 13px 10px ;
  cursor: pointer;
}
.button-modal-daily{
  .bbbbbb{
    border-radius: 60px !important;
    border: 1px solid #3568C1 !important;
    background: var(--White, #FFF) !important;
    color: #3553D5 !important;
    &:hover{
      border: 1px solid #3568C1 !important;
      background: var(--White, #FFF) !important;
      color: #3553D5 !important;
    }
  }
}
.ababab{
  border: none !important;
}
.end{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  margin-top: 80px;
}
