@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

.alltransaction{
    .inner-content {
        padding: 20px 15px;

    }
    .card {
        h6 {
            font-size: 12px;
            margin-top: 5px;
        }

        li.top {
            img {
                position: relative;
        top: -12px;
        left: 10px;
            }

        }
        
        .btn {
            background: #FFFFFF;
            border: 1.5px solid #808099;
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            text-align: left;
            position: relative;
        }


        .pt10 {
            padding-top: 10px;
        }

        .pt20 {
            padding-top: 20px;
        }

        .dropup .dropdown-toggle:after, .dropdown .dropdown-toggle:after {
            position: absolute;
            right: 8px;
            top: 15px;
        }
    }
}