.addnewcategory {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
    min-height: 100vh;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 15px;
    }

    input {
        background: #FAFAFB;
        border: 1px solid #EFEFEF;
        border-radius: 5px;
        width: 100%;
        padding: 20px;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #CDCDCD;
        }
    }

    .btnslasts {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .bluebtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 13px 59px;
            background: linear-gradient(89.93deg, #3553D5 50.15%, #0C194E 217.12%);
            border-radius: 60px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
            border: 1px solid transparent;

            &:hover {
                color: #3553D5;
                background: #FFFFFF;
                border: 1px solid #3568C1;
                transition: 1s;
            }
        }

        .whitebtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 13px 59px;
            background: #FFFFFF;
            border-radius: 60px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            border: 1px solid #3568C1;
            color: #3553D5;

            &:hover {
                color: #FFFFFF;
                background: linear-gradient(89.93deg, #3553D5 50.15%, #0C194E 217.12%);
                border: 1px solid transparent;
                transition: 1s;
            }
        }
    }
}